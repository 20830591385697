import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { withLightTheme } from '../../../theme'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { Button, Dialog, DialogActions, DialogContent, Typography, DialogTitle, DialogContentText, Divider, Switch, FormControlLabel, List, ListItem, ListItemText, ListItemIcon, Checkbox, LinearProgress } from '@material-ui/core'
import { ORG_PARAM_KEY_ACCESSIBILITY, ORG_PARAM_KEY_ACCESSIBILITY_ADMINS_EXCLUDED } from '../../../m2m-cloud-api/MessageLog/Contants'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import Searchbox from '../../../Libs/Components/Searchbox'

class UserAccessManagementDialog extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            restrictAccess: false,
            selectedUserIds: [],
            exludedAdminUserIds: [],
            users: null,
            admins: null,
            searchTerm: '',
            org: null,
            loading: true,
            changed: false,
            errorMessage: null
        }
    }

    async componentDidMount() {
        try {
            const { usersGroup, adminsGroup }  = await this.context.prepareRootOrgGroups()
            const mangeableUserIds = usersGroup.getUsers()
            const users = []
            for (let index = 0; index < mangeableUserIds.length; index++) {
                const mangeableUserId = mangeableUserIds[index]
                const user = await this.context.getPublicUser(mangeableUserId)
                users.push(user)
            }

            const adminUserIds = adminsGroup.getUsers()
            const admins = []
            for (let index = 0; index < adminUserIds.length; index++) {
                const adminUserId = adminUserIds[index]
                const user = await this.context.getPublicUser(adminUserId)
                admins.push(user)
            }

            let selectedUserIds = []
            let exludedAdminUserIds = []
            let restrictAccess = false
            const org = await this.context.api.orgService.read(this.props.orgId)

            let access = org.getParam(ORG_PARAM_KEY_ACCESSIBILITY)
            if (access !== null && access !== undefined && access.trim() !== '*') {
                restrictAccess = true
                if (access.trim() !== "") {
                    selectedUserIds = access.split(',')
                }
            }
            
            access = org.getParam(ORG_PARAM_KEY_ACCESSIBILITY_ADMINS_EXCLUDED)
            if (access !== null && access !== undefined) {
                restrictAccess = true
                if (access.trim() !== "") {
                    exludedAdminUserIds = access.split(',')
                }
            }
            this.setState({users, admins, loading: false, org, selectedUserIds, exludedAdminUserIds, restrictAccess})      
        } catch (error) {
            let message = mapErrorMessage(error)
            this.setState({ errorMessage: message, loading: false })
        }
    }

    handleUserSelect(userId, isAdmin, cheched) {
        let { selectedUserIds, exludedAdminUserIds } = this.state
console.log('handleUserSelect(userId, isAdmin, cheched)', userId, isAdmin, cheched)
        if (isAdmin) {
            if ( exludedAdminUserIds.indexOf(userId) === -1 ) {
                exludedAdminUserIds.push(userId)
                selectedUserIds = selectedUserIds.filter( id => id !== userId )
            } else {
                exludedAdminUserIds = exludedAdminUserIds.filter( id => id !== userId )
            }
            console.log('exludedAdminUserIds', exludedAdminUserIds)
            this.setState({
                selectedUserIds,
                exludedAdminUserIds,
                changed: true
            })
        } else {
            if ( selectedUserIds.indexOf(userId) === -1 ) {
                selectedUserIds.push(userId)
                exludedAdminUserIds = exludedAdminUserIds.filter( id => id !== userId )
            } else {
                selectedUserIds = selectedUserIds.filter( id => id !== userId )
            }
            this.setState({
                selectedUserIds,
                changed: true
            })
        }

    }

    async handleSubmit() {
        const { restrictAccess, selectedUserIds, exludedAdminUserIds, org } = this.state

        this.setState({loading: true})
        
        try {
            if (restrictAccess) {
                await this.context.api.orgService.putParam(org.getId(), ORG_PARAM_KEY_ACCESSIBILITY, selectedUserIds.join(','))
                await this.context.api.orgService.putParam(org.getId(), ORG_PARAM_KEY_ACCESSIBILITY_ADMINS_EXCLUDED, exludedAdminUserIds.join(','))
            } else {
                await this.context.api.orgService.deleteParam(org.getId(), ORG_PARAM_KEY_ACCESSIBILITY)
                await this.context.api.orgService.deleteParam(org.getId(), ORG_PARAM_KEY_ACCESSIBILITY_ADMINS_EXCLUDED)
            }
    
            this.setState({ loading: true, errorMessage: null }, () => {
                this.props.onSuccess()
            })
                
        } catch (error) {
            let message = mapErrorMessage(error)
            this.setState({ errorMessage: message, loading: false })
        }
    }

    render() {
        const { restrictAccess, selectedUserIds, exludedAdminUserIds, loading, users, admins, changed, org, errorMessage, searchTerm } = this.state
        const { t, classes } = this.props

        console.log('exludedAdminUserIds', exludedAdminUserIds, selectedUserIds)

        const adminItems = []
        admins && admins.map( admin => {
            adminItems.push({
                id: admin.getId(),
                nickname: admin.getNickname(),
                isAdmin: true
            })
        })
        const userItems = []
        users && users.map( user => {
            const userAdded = adminItems.find( item => item.id === user.id)
            if (!userAdded) {
                userItems.push({
                    id: user.getId(),
                    nickname: user.getNickname(),
                    isAdmin: false
                })
            }
        })

        const filteredUsers = [...adminItems, ...userItems].filter( (user) =>  !searchTerm || searchTerm.trim() === "" || user.nickname.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ).sort((a, b) => a.nickname.localeCompare(b.nickname))
        const countVisibleUsers = (selectedUserIds && selectedUserIds.length || 0) + adminItems.length

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    { loading && <LinearProgress className={classes.progress} /> }
                    <DialogTitle id="form-dialog-title">{t('access_management')}</DialogTitle>
                    <Divider/>
                    <div className={classes.headerContent}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={restrictAccess}
                                    onChange={(event) => this.setState({restrictAccess: event.target.checked, changed: true})}
                                    disabled={loading}
                                    color="primary"/>
                            }
                            label={t('restrict_access')} />
                    </div>
                    { restrictAccess && <Searchbox
                        value={searchTerm}
                        className={classes.searchbox}
                        onChange={(value) => this.setState({searchTerm: value})}/> }
                    <Typography variant="subtitle2" className={classes.listHeader}>{!restrictAccess ? t('visible_for') : t('visible_for_user', { count: countVisibleUsers }) }</Typography>
                    <DialogContent className={classes.dialogContent}>
                        <List className={classes.list}>
                            { restrictAccess && filteredUsers.map( (user) => (
                                <ListItem className={classes.listItem}>
                                    <ListItemIcon style={{minWidth: 32}}>
                                        <Checkbox
                                            edge="start"
                                            checked={(user.isAdmin && exludedAdminUserIds.indexOf(user.id) === -1) || selectedUserIds.indexOf(user.id) >= 0}
                                            onChange={(event) => this.handleUserSelect(user.id, user.isAdmin, event.target.checked)}
                                            tabIndex={-1}
                                            color="primary"
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText className={classes.listItemText} primary={user.nickname} secondary={user.isAdmin ? <Typography className={[classes.listItemTextSecondary, classes.listItemTextSecondaryHighlighed].join(' ')} variant="subtitle2">{t('admin')}</Typography> : null} />
                                </ListItem>
                            ))}
                            { restrictAccess && filteredUsers.length === 0 && (
                                <Typography variant="subtitle2" className={classes.emptyListItem}>{t('no_users_available')}</Typography>
                            )}
                            { !restrictAccess && (
                                <Typography variant="subtitle2" className={classes.emptyListItem}>{t('all_users')}</Typography>
                            )}
                        </List>
                        {errorMessage && <DialogContentText style={{marginTop: 20}} color="error">{errorMessage}</DialogContentText>}
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button disabled={loading} onClick={this.props.onCancel}>
                            {t('cancel')}
                        </Button>
                        <Button disabled={!changed || loading} onClick={() => this.handleSubmit()} color="primary">
                            {t('apply')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

UserAccessManagementDialog.contextType = PageContext

UserAccessManagementDialog.propTypes = {
    open: PropTypes.bool,
    orgId: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

const styles = theme => ({
    dialogContent: {
    },
    headerContent: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    list: {
        padding: 0
    },
    listHeader: {
        backgroundColor: theme.palette.grey['100'],
        color: theme.palette.grey['A900'],
        paddingLeft: theme.spacing(3)
    },
    listItem: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    listItemText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    listItemTextSecondary: {
        backgroundColor: theme.palette.grey['100'],
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        marginLeft: 10
    },
    listItemTextSecondaryHighlighed: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
    },
    listItemSecondaryAction: {
        right: theme.spacing(1)
    },
    emptyListItem: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    searchbox: {
        maxWidth: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
})


export default withTranslation()(withLightTheme(withStyles(styles)(UserAccessManagementDialog)))