import { ROOT_PARAM_KEY, ROOT_PARAM_OLD_FALLBACK_KEY } from '../../../MessageLog/Contants';

const PARAM_PREFIX = 'core-auth.param';
export const PARAM_TYPE = `${PARAM_PREFIX}.type`;
export const PARAM_LANGUAGE = `lang`;
export const PARAM_FIRST_NAME = `${PARAM_PREFIX}.firstname`;
export const PARAM_LAST_NAME = `${PARAM_PREFIX}.lastname`;
export const PARAM_NAME = `${PARAM_PREFIX}.name`;
export const PARAM_DESCRIPTION = `${PARAM_PREFIX}.desc`;
export const PARAM_REGISTRATION_SERVICE = `registration.app`;
export const PARAM_MFA = 'core-auth.mfa.required';
export const PARAM_PN_SOUND = 'pn-sound';
export const PARAM_MONITORING_AREA_FILTER = 'monitoring.area-filter';
export const PARAM_MONITORING_DETAILED_REPORTS_RECURSIVE_DEPTH = 'monitoring.detailedreports.recursivedepth';
export const PARAM_ADVANCED_SEARCH_FILTER = `${PARAM_PREFIX}.search-filter`;

const PARAM_PUBLIC_PREFIX = 'core-auth.public';
export const PARAM_NICKNAME = `${PARAM_PUBLIC_PREFIX}.nick`;

const TYPES = ['person'];

export const PARAM_FORM_TYPE_FIELD = {
  id: PARAM_TYPE,
  title: 'type',
  type: 'select',
  options: TYPES,
  validators: [],
  errorMessages: [],
};

const LANGUAGES = ['de', 'en', 'es'];

export const PARAM_FORM_LANGUAGE_FIELD = {
  id: PARAM_LANGUAGE,
  title: 'language',
  type: 'select',
  options: LANGUAGES,
  validators: [],
  errorMessages: [],
};

export const PARAM_FORM_NICKNAME_FIELD = {
  id: PARAM_NICKNAME,
  title: 'nickname',
  type: 'String',
  validators: ['required', 'trim'],
  errorMessages: ['this_field_is_required', 'this_field_is_required'],
};

export const PARAM_FORM_USER_FIELDS = [
  {
    id: PARAM_FIRST_NAME,
    title: 'firstname',
    type: 'String',
    validators: ['required', 'trim'],
    errorMessages: ['this_field_is_required', 'this_field_is_required'],
  },
  {
    id: PARAM_LAST_NAME,
    title: 'lastname',
    type: 'String',
    validators: ['required', 'trim'],
    errorMessages: ['this_field_is_required', 'this_field_is_required'],
  },
];

export const PARAM_FORM_TECHNICAL_USER_FIELDS = [
  {
    id: PARAM_NAME,
    title: 'name',
    type: 'String',
    validators: ['required', 'trim'],
    errorMessages: ['this_field_is_required', 'this_field_is_required'],
  },
  {
    id: PARAM_DESCRIPTION,
    title: 'description',
    type: 'Text',
    validators: [],
    errorMessages: [],
  },
];

export const PARAM_FORM_PN_SOUNDS_FIELD = {
  id: PARAM_PN_SOUND,
  title: 'notification_sound_title',
  type: 'select',
  options: [],
  validators: [],
  errorMessages: [],
};

export default class User {
  /**
   * @param {Object<String,Uid,Array>}
   */
  constructor({ email, id, params }) {
    this.email = email;
    this.id = id;
    this.params = params;
    this.userRoleMeta = null;
  }

  /**
   * Get user email address
   * @returns {String}
   */
  getEmail() {
    return this.email;
  }

  /**
   * Get user id
   * @returns {Uid}
   */
  getUserId() {
    return this.id;
  }

  /**
   * set user role meta
   * @param {UserRoleMeta} userRoleMeta
   */
  setUserRoleMeta(userRoleMeta) {
    this.userRoleMeta = userRoleMeta;
  }

  /**
   * get user role meta
   * @returns {UserRoleMeta}
   */
  getUserRoleMeta(userRoleMeta) {
    return this.userRoleMeta;
  }

  /**
   * Get user params
   * @returns {Array<Object>}
   */
  getParams() {
    return this.params;
  }

  /**
   * Get param
   * @param {String} name
   * @returns {String}
   */
  getParam(name) {
    const value = this.params && this.params[name];
    if (!value) {
      // check fallback params with 'msg-app' prefix (old prefix)
      const fallbackName = name.replace(`${ROOT_PARAM_KEY}:`, `${ROOT_PARAM_OLD_FALLBACK_KEY}:`);
      const fallbackValue = this.params && this.params[fallbackName];
      if (fallbackValue && fallbackValue.trim() !== '') {
        console.warn('return fallback user param: ', fallbackName, 'value: ', fallbackValue);
        return fallbackValue;
      }
    }
    return value;
  }

  /**
   * set param
   * @param {String} name
   * @param {String} value
   */
  setParam(name, value) {
    this.params[name] = value;
  }

  /**
   * Get type
   * @returns {String} - 'person' or 'technicaluser'
   */
  getType() {
    return this.getParam(PARAM_TYPE);
  }

  /**
   * Is technical user
   * @returns {Boolean}
   */
  isTechnicalUser() {
    return this.getType() === 'technicaluser';
  }

  /**
   * Get firstname
   * @returns {String}
   */
  getFirstName() {
    return this.getParam(PARAM_FIRST_NAME);
  }

  /**
   * Get lastname
   * @returns {String}
   */
  getLastName() {
    return this.getParam(PARAM_LAST_NAME);
  }

  /**
   * Get nick name
   * @returns {String}
   */
  getNickName() {
    return this.getParam(PARAM_NICKNAME);
  }

  /**
   * Get name
   * @returns {String}
   */
  getName() {
    return this.getParam(PARAM_NAME);
  }

  /**
   * Get description
   * @returns {String}
   */
  getDescription() {
    return this.getParam(PARAM_DESCRIPTION);
  }

  /**
   * Get user language
   */
  getUserLanguage() {
    return this.getParam(PARAM_LANGUAGE);
  }

  /**
   * Is Multi-factor authentication enabled
   * @returns {Boolean}
   */
  isMfaEnabled() {
    const param = this.getParam(PARAM_MFA) === 'true' ? true : false;
    return param;
  }
}
