import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import AlertDialog from './AlertDialog'
import Moment from 'react-moment'

const styles = theme => ({
    progress: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0
    },
    title: {
        paddingLeft: theme.spacing(2)
    },
    content: {
        paddingLeft: 0,
        paddingRight: 0
    },
	errorText: {
		color: theme.palette.error.main,
		marginTop: 20
    },
    body: {
        minHeight: 400
    },
    tableWrapper: {
        overflow: 'auto'
    },
    table: {
        flex: 1,
        maxHeight: '100%',
        overflow: 'auto',
    },
    header: {
        backgroundColor: 'transparent'
    }
})

export class InvitationsDialog extends Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			errorMessage: null
		}
    }

    componentDidMount() {
       this.loadPendingInvitations() 
    }

    acceptInvitation() {
        const { authzService, userService } = this.props
        
        const { pendingInvitations, invitationToAccept } = this.state
        this.setState({ loading: true })
        const groupId = invitationToAccept.getGroupId()
        authzService.acceptInvitation(groupId)
            .then(success => {
                this.setState({ 
                    loading: false, 
                    pendingInvitations: pendingInvitations.filter( pendingInvitation => !(pendingInvitation.getInviter() === invitationToAccept.getInviter() && pendingInvitation.getGroupId() === groupId ) ),
                    invitationToAccept: null
                })
                if (this.props.onSuccess) {
                    this.props.onSuccess()
                }
            })
            .catch(error => {
                console.log('acceptInvitation error', error)
                this.setState({loading: false, error })
            })
    }
    
	render() {
		const { loading, errorMessage, pendingInvitations, publicUsers, groups, invitationToAccept } = this.state
        const { t, classes } = this.props
        
        const fetchGroupName = (invation) => {
            const group = groups.find(group => group.getId() === invation.getGroupId())
            return group ? group.getName() : invation.getGroupId()
        }

        const fetchNickName = (userId) => {
            const publicUser = publicUsers.find(publicUser => publicUser.getId() === userId)
            return publicUser ? publicUser.getNickname() : userId
        }

		return (
			<div>
				<Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'sm'} aria-labelledby="form-dialog-title">
                    { loading && <LinearProgress className={classes.progress} /> }
					<DialogTitle className={classes.title} id="form-dialog-title">{t('invitations')}</DialogTitle>
					<DialogContent classes={{root: classes.content}}>
                        <div className={classes.tableWrapper}>
                            <Table stickyHeader className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.header} align="left">{t('inviter')}</TableCell>
                                        <TableCell className={classes.header} align="left">{t('group')}</TableCell>
                                        <TableCell className={classes.header} align="left">{t('created')}</TableCell>
                                        <TableCell className={classes.header} align="left">{' '}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.body}>
                                    {pendingInvitations && pendingInvitations.map(pendingInvitation => (
                                        <TableRow key={pendingInvitation.getInviter()}>
                                            <TableCell align="left">
                                                {fetchNickName(pendingInvitation.getInviter())}
                                            </TableCell>
                                            <TableCell align="left">
                                                {fetchGroupName(pendingInvitation)}
                                            </TableCell>
                                            <TableCell aling="left">
                                                <Moment format="YYYY-MM-DD HH:mm">
                                                    {pendingInvitation.getCreationTS()}
                                                </Moment>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Button onClick={() => { this.setState({ invitationToAccept: pendingInvitation }) }} color="primary" className={classes.button}>
                                                    {t('accept')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {pendingInvitations && pendingInvitations.length === 0 && (
                                        <TableCell align="left" colSpan={4}>
                                            {t('no_invitations_available')}
                                        </TableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
					</DialogContent>
					<DialogActions>
						<Button disabled={loading} onClick={this.props.onClose} color="primary">
							{t('close')}
						</Button>
					</DialogActions>
				</Dialog>
                {invitationToAccept && (
                    <AlertDialog
                        open={invitationToAccept ? true : false}
                        title={t('invitation_accept_comfirmation_title')}
                        message={t('invitation_accept_confirmation_description')}
                        onCancel={() => this.setState({ invitationToAccept: null })}
                        submitButtonTitle={t('accept')}
                        onSubmit={() => this.acceptInvitation()}
                    />
                )}
				{errorMessage && (
					<AlertDialog
						open={errorMessage ? true : false}
						title={t('error')}
						message={errorMessage}
						submitButtonTitle={t('ok')}
						onSubmit={() => this.setState({ errorMessage: null })}
					/>
				)}
			</div>
		)
	}
    
    async loadPendingInvitations() {
        const { authzService, userService } = this.props

        try {
            const pendingInvitations = await authzService.listPendingInvitations()
            let groupIds = []
            let userIds = []

            /*offeredInvitations && offeredInvitations.map( invitation => {
                const isUser = usersGroup.getId() === invitation.groupId
                const isAdmin = adminsGroup.getId() === invitation.groupId
                const hasAdminGroup = offeredInvitations.find(invitation => invitation.groupId === adminsGroup.getId() )
                if ( !(hasAdminGroup && isUser) ) {
                    const user = users[invitation.invitee]
                    entities.push( user ? { id: user.getId(), title: user.getNickname(), invitation: true, isAdmin } : { id: invitation.invitee, title: invitation.invitee, invitation: true, isAdmin } )
                }
            })*/

            pendingInvitations && pendingInvitations.map(invitation => {
                if (groupIds.indexOf(invitation.getGroupId()) === -1) {
                    groupIds.push(invitation.getGroupId())
                }
                if (userIds.indexOf(invitation.getInvitee()) === -1) {
                    userIds.push(invitation.getInvitee())
                }
                if (userIds.indexOf(invitation.getInviter()) === -1) {
                    userIds.push(invitation.getInviter())
                }
            })
            let groups = []
            if (groupIds.length > 0) {
                groups = await authzService.readMultipleGroups(groupIds)
            }

            console.log('groups', groups)

            const publicUsers = []
            for (let index = 0; index < userIds.length; index++) {
                const userId = userIds[index]
                publicUsers.push( await userService.getPublicUser(userId) )
            }

            this.setState({loading: false, pendingInvitations, groups, publicUsers})

        } catch (error) {
            console.warn("UserButton, loadPendingInvitations error: ", error)
        }
    }

}

InvitationsDialog.propTypes = {
    api: PropTypes.object.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired
}

export default withTranslation()(withStyles(styles)(InvitationsDialog))
