import User from "./Models/User";
import PublicUser from "./Models/PublicUser";
import UserRoleMeta from "./Models/UserRoleMeta";
import MemoryCache from "../MemoryCache";
import { ERROR } from "../Errors";
import AccessToken from "./Models/AccessToken";

const wait = (seconds) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds * 1000);
  });

const API_SERVICE_KEY_CORE_AUTH = "core-auth";
const { getInvalidParameterError } = require("../Errors");

const PUBLIC_USER_CACHE_DURATION = 4 * 60 * 60 * 1000;

export default class UserService {
  /**
   * @param {Api} api
   * @param {Function} onLogout
   */
  constructor(api, onLogin = null, onLogout = null, onBeforeLogout = null) {
    this.api = api;
    this.onBeforeLogout = onBeforeLogout;
    this.onLogout = onLogout;
    this.onLogin = onLogin;
    this.authToken = null;
    this.user = null;
    this.publicUserCache = new MemoryCache(PUBLIC_USER_CACHE_DURATION);

    this.setRefreshTokenDate = () => {
      this.refreshTokenDate = new Date();
    };

    this.api.setTokenValidityHandler(
      () =>
        new Promise((resolve, reject) => {
          const shouldRefreshToken = this.shouldRefreshToken();
          if (shouldRefreshToken) {
            this.renewToken()
              .then(() => {
                this.setRefreshTokenDate();
                resolve(true);
              })
              .catch((error) => {
                const errorType = error && error.getErrorType && error.getErrorType();
                if (errorType === ERROR.FORBIDDEN) {
                  console.warn("invalid (expired) refresh token, logout current user and go to login page...");
                  this.logoutUser();
                }
                resolve(false);
              });
            return;
          }
          resolve(null);
        })
    );

    this.api.setUnauthorizedErrorHandler(() => {
      if (this.user) {
        console.warn("unauthorized error received from api, try to refresh token...");
        this.setRefreshTokenDate();
        this.renewToken()
          .then(() => {
            console.log("token refreshed after unauthorized error.");
          })
          .catch((error) => {
            console.log("token refresh failed after unauthorized error, error:", error);
            const errorType = error && error.getErrorType && error.getErrorType();
            if (errorType === ERROR.FORBIDDEN) {
              console.warn("invalid (expired) refresh token, logout current user and go to login page...");
              this.logoutUser();
            }
          });
      }
      //console.warn('unauthorized error received from api, logout current user and go to login page...', this.user )
      //this.logoutUser()
    });

    this.initTabChangeHandler();
  }

  initTabChangeHandler() {
    const me = this;
    const isBrowser = typeof document != "undefined";
    if (isBrowser) {
      window.onfocus = function () {
        setTimeout(async () => {
          const sessionData = await me.api.storage.get(API_SERVICE_KEY_CORE_AUTH);
          //console.log('sessionData', sessionData, me.user, me.user.id !== sessionData.userId)
          if (sessionData && me.user && me.user.id !== sessionData.userId) {
            console.warn("[ON TAB ACTIVE] Another user has logged in in the meantime, refreshing tab...");
            window.location.reload();
          }
        }, 1000);
      };
    }
  }

  /**
   * init session
   * @returns {Promise}
   */
  initSession() {
    return new Promise(async (resolve, reject) => {
      try {
        const sessionData = await this.api.storage.get(API_SERVICE_KEY_CORE_AUTH);
        this.handleSession({ userId: sessionData?.userId || null, authToken: null });
        await this.renewToken(1);
        const user = await this.loadUser(this.userId);
        this.user = user;
        const ownUserRoleMeta = await this.readOwnUserRoleMeta();
        this.user.setUserRoleMeta(ownUserRoleMeta);
        resolve(this.user);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * handle session
   * @param {Object} session
   */
  handleSession({ userId, authToken }) {
    this.authToken = authToken;
    this.userId = userId;
    this.api.setToken(authToken);
  }

  /**
   * should refresh token
   * @returns {Boolean}
   */
  shouldRefreshToken() {
    //console.log('[shouldRefreshToken] has no auth token or refresh token.', this.authToken)
    if (!this.authToken) {
      //console.log('[shouldRefreshToken] has no auth token.')
      return false;
    }
    /** Token refresh workaround (Samsung TV) */
    if (!this.refreshTokenDate) {
      //console.warn('[shouldRefreshToken] refresh token, invalid refreshTokenDate: ', this.refreshTokenDate)
      this.setRefreshTokenDate();
      return true;
    }
    if (new Date().getTime() - this.refreshTokenDate.getTime() > 3 * 60 * 1000) {
      this.setRefreshTokenDate();
      //console.warn('[shouldRefreshToken] refresh token refreshTokenDate: ', this.refreshTokenDate)
      return true;
    }
    return false;
  }

  /**
   * refresh token
   */
  renewToken(maxAttempts = 6) {
    return new Promise(async (resolve, reject) => {
      if (!this.userId) {
        console.warn("has no user id.");
        this.logoutUser();
        reject("invalid user id session.");
        return;
      }

      const tryRenewToken = () => {
        const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/refresh", {
          ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
          ":id": this.userId,
        });
        const headers = { Authorization: null }; // refresh token is used from cookie instead of Authorization header
        return this.api.request("POST", url, null, headers, false);
      };

      let session = null;
      let countAttempts = 1;
      let lastError = null;
      do {
        try {
          const _session = await tryRenewToken();
          if (_session) {
            session = _session;
            this.setRefreshTokenDate();
            this.handleSession(session);
            resolve();
          } else {
            if (maxAttempts > 1) {
              await wait(15);
            }
            countAttempts++;
          }
        } catch (error) {
          console.warn("token refresh (#" + countAttempts + ") error: ", error);
          lastError = error;
          const errorType = error && error.getErrorType && error.getErrorType();
          if (errorType === ERROR.FORBIDDEN) {
            countAttempts = maxAttempts;
          } else {
            if (maxAttempts > 1) {
              await wait(15);
            }
            countAttempts++;
          }
        }
      } while (session === null && countAttempts < maxAttempts);
      reject(lastError);
    });
  }

  /**
   * init session
   * @param {func} callback
   * @returns {undefined}
   */
  logoutUser(callback) {
    if (this.onBeforeLogout) {
      this.onBeforeLogout(this.user);
    }
    this.authToken = null;
    this.api.setToken(null);
    this.user = null;
    this.api.storage.remove(API_SERVICE_KEY_CORE_AUTH).then(async () => {
      if (this.onLogout) {
        this.publicUserCache.resetCache();
        this.onLogout();
        try {
          const url = this.api.buildRequestURL("/:serviceKey/api/user/logout", {
            ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
          });
          await this.api.request("GET", url, null, null, false);
        } catch (error) {
          console.log("log out failed");
        }
        if (callback) callback();
      }
    });
  }

  /**
   * Get active user
   * @returns {User}
   */
  getActiveUser() {
    return this.user;
  }

  /**
   * Reload active user
   * @returns {User}
   */
  reloadActiveUser() {
    return this.loadUser(this.user.getUserId()).then((user) => {
      this.user = user;
      return user;
    });
  }

  /**
   * Create a new user with the given email address
   * @param {String} email (required)
   * @param {Object} additionalProperties (optional)
   * @param {Uid} initialGroup (optional)
   *
   * @returns Promise<{userId}>
   */
  createUser(email, params = null, initialGroup = null) {
    if (!email) {
      return Promise.reject(getInvalidParameterError("email"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = { email: email };
    if (params && Object.keys(params).length > 0) {
      body["params"] = params;
    }
    if (initialGroup) {
      body["initialGroup"] = initialGroup;
    }
    return this.api.request("POST", url, body, null, false);
  }

  /**
   * Activate a previously created user
   * @param {Uid} userId (required)
   * @param {Integer} activationCode (required)
   * @param {String} password (required)
   *
   * @returns Promise<{Boolean}>
   */
  activateUser(userId, activationCode, password) {
    if (!activationCode) {
      return Promise.reject(getInvalidParameterError("activationCode"));
    }
    if (!password) {
      return Promise.reject(getInvalidParameterError("password"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/activate", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
    });
    const body = { activationCode, password };
    return this.api.request("POST", url, body, null, false).then((result) => result.done);
  }

  /**
   * Load user (Authentication required.)
   * @param {Uid} userId (required)
   *
   * @returns Promise<User>
   */
  loadUser(userId) {
    if (!userId) {
      return Promise.reject(getInvalidParameterError("userId"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
    });
    return this.api.request("GET", url, null).then((user) => new User(user));
  }

  getPublicUser(userId, useCache = true) {
    if (!userId) {
      return Promise.reject(getInvalidParameterError("userId"));
    }

    if (useCache) {
      const cachedUser = this.publicUserCache.getItem(userId);
      if (cachedUser) {
        if (cachedUser) return Promise.resolve(cachedUser);
      }
    }

    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/public", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
    });
    return this.api.request("GET", url, null).then((publicUser) => {
      const user = new PublicUser(publicUser);
      this.publicUserCache.upsertItem(user);
      return user;
    });
  }

  /**
   * Login via email/password to retrieve a session token.
   * @param {String} email (required)
   * @param {String} password (required)
   * @param {String} mfaData code
   * @param {String} mfaType totp | recovery
   *
   * @returns Promise<User>
   */
  loginUser(email, password, mfaData = null, mfaType = "totp") {
    if (!email) {
      return Promise.reject(getInvalidParameterError("email"));
    }
    if (!password) {
      return Promise.reject(getInvalidParameterError("password"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/login", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    let body = { email, password };
    if (mfaData) {
      body = {
        ...body,
        additionalCredentials: {
          mfaType: mfaType,
          mfaData: mfaData,
        },
      };
    }
    return this.api
      .request("POST", url, body, null, false)
      .then((session) => {
        this.handleSession(session);
        const data = { userId: session.userId };
        return this.api.storage.set(API_SERVICE_KEY_CORE_AUTH, data).then(() => {
          this.setRefreshTokenDate();
          return this.initSession();
        });
      })
      .then((user) => {
        if (user && this.onLogin) {
          this.onLogin();
        }
        return user;
      });
  }

  /**
   * Reset password by email address
   * @param {String} email
   * @returns Promise<{Boolean}>
   */

  requestPasswordResetByMail(email) {
    if (!email) {
      return Promise.reject(getInvalidParameterError("email"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/resetPassword/requestByMail", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = { email };
    return this.api.request("POST", url, body).then((result) => result.done);
  }

  /**
   * Reset password by code
   * @param {String} code
   * @param {String} email
   * @param {String} password
   * @returns Promise<{Boolean}>
   */
  resetPasswordByCode(code, email, password) {
    if (!code) {
      return Promise.reject(getInvalidParameterError("code"));
    }
    if (!email) {
      return Promise.reject(getInvalidParameterError("email"));
    }
    if (!password) {
      return Promise.reject(getInvalidParameterError("password"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/resetPassword", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = { code, email, password };
    return this.api.request("POST", url, body).then((result) => result.done);
  }

  /**
   * Validate activation code
   * @param {Uid} userId
   * @param {String} activationCode
   * @returns Promise<{Boolean}>
   */
  validateActivationCode(userId, activationCode) {
    if (!userId) {
      return Promise.reject(getInvalidParameterError("userId"));
    }
    if (!activationCode) {
      return Promise.reject(getInvalidParameterError("activationCode"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/validateCode/:code", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
      ":code": activationCode,
    });
    return this.api.request("GET", url, null, null, false);
  }

  /**
   * change password
   * @param {Uid} userId
   * @param {String} password
   * @param {String} oldPassword
   * @returns Promise<{Boolean}>
   */
  changePassword(userId, password, oldPassword) {
    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/password", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
    });
    const body = { value: password, oldPassword };
    return this.api.request("PUT", url, body).then((result) => result.done);
  }
  ƒ;

  /**
   * Set user param
   * @param {Uid} userId
   * @param {String} key
   * @param {String} value
   * @param {Boolean} reloadUser
   * @returns Promise<{Boolean}>
   */
  putParam(userId, key, value, reloadUser = true) {
    // set param temporary
    if (this.user) {
      this.user.setParam(key, value);
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/params/:key", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
      ":key": key,
    });
    const body = { value: value };
    return this.api.request("PUT", url, body).then((result) => {
      if (reloadUser && result.done) {
        return this.loadUser(userId).then((user) => {
          this.user = user;
          return this.user;
        });
      }
      return result.done;
    });
  }

  /**
   * Delete user param
   * @param {Uid} userId
   * @param {String} key
   * @param {Boolean} reloadUser
   */
  deleteParam(userId, key, reloadUser = false) {
    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/params/:key", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
      ":key": key,
    });
    return this.api.request("DELETE", url, null).then((result) => {
      if (reloadUser && result.done) {
        return this.loadUser(userId).then((user) => {
          this.user = user;
          return this.user;
        });
      }
      return result.done;
    });
  }

  /**
   * Read user role meta (Authentication required.)
   * @param {Uid} userId
   * @returns {UserRoleMeta}
   */
  readUserRoleMeta(userId) {
    if (!userId) {
      return Promise.reject(getInvalidParameterError("userId"));
    }
    const url = this.api.buildRequestURL("/:serviceKey/api/user/:id/meta/role", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": userId,
    });
    return this.api.request("GET", url, null).then((json) => new UserRoleMeta(json));
  }

  /**
   * Read user role meta from current user (Authentication required.)
   * @returns {UserRoleMeta}
   */
  readOwnUserRoleMeta() {
    return this.readUserRoleMeta(this.user && this.user.getUserId());
  }

  /**
   * Invite user to a group
   * @param {Uid} userId
   * @param {string} mail
   *
   * @returns Promise<Boolean>
   */
  inviteToGroup(groupId, mail) {
    const url = this.api.buildRequestURL("/:serviceKey/api/inviteToGroup", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = { groupId, mail };
    return this.api.request("POST", url, body).then((result) => result.done);
  }

  /**
   * Request for the multi-factor auth
   * @param {String} type totp | recovery
   * @returns Promise<Object>
   */
  requestAuthFactor(type) {
    const url = this.api.buildRequestURL("/:serviceKey/api/user/requestAuthFactor", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = {
      type: type,
      params: {},
    };
    return this.api.request("POST", url, body).then((result) => result);
  }

  /**
   * Install the multi-factor auth
   * @param {String} type totp || recovery
   * @param {String} code
   * @returns Promise<Object>
   */
  installAuthFactor(type, code) {
    const url = this.api.buildRequestURL("/:serviceKey/api/user/installAuthFactor", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = {
      type: type,
      params: { code: code },
    };
    return this.api.request("POST", url, body).then((result) => result);
  }

  /**
   * Delete the multi-factor auth
   * @param {String} type totp | recovery
   * @returns Promise<Boolean>
   */
  deleteAuthFactor(type) {
    const url = this.api.buildRequestURL("/:serviceKey/api/user/removeAuthFactor/:type", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":type": type,
    });
    return this.api.request("DELETE", url, null).then((result) => result.done);
  }

  /**
   * get access tokens
   * @returns {Array<AccessToken>}
   */
  getAccessTokens() {
    const url = this.api.buildRequestURL("/:serviceKey/api/tokens", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    return this.api.request("GET", url, null).then((json) => json.map((token) => new AccessToken(token)));
  }

  /**
   * add a access token
   * @param {String} name
   * @param {Date} validityDate
   * @returns {Object<AccessToken>}
   */
  addAccessToken(name, validityDate) {
    const url = this.api.buildRequestURL("/:serviceKey/api/tokens", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = {
      name,
      validity: validityDate,
    };
    return this.api.request("POST", url, body).then((result) => new AccessToken(result));
  }

  /**
   * Delete token
   * @param {String} tokenId
   * @returns {Boolean}
   */
  deleteAccessToken(tokenId) {
    const url = this.api.buildRequestURL("/:serviceKey/api/tokens/:id", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
      ":id": tokenId,
    });
    return this.api.request("DELETE", url, null).then((result) => result.done);
  }

  /**
   * Login via otp link
   * @param {String} token (required)
   *
   * @returns Promise<User>
   */
  loginWithOtpLink(token) {
    if (!token) {
      return Promise.reject(getInvalidParameterError("token"));
    }
    const url = this.api.buildRequestURL(`/:serviceKey/api/user/loginOTP?token=${token}`, {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    return this.api
      .request("GET", url, null)
      .then((session) => {
        this.handleSession(session);
        const data = { userId: session.userId };
        return this.api.storage.set(API_SERVICE_KEY_CORE_AUTH, data).then(() => {
          this.setRefreshTokenDate();
          return this.initSession();
        });
      })
      .then((user) => {
        if (user && this.onLogin) {
          this.onLogin();
        }
        return user;
      });
  }

  /**
   * request otp login link
   * @param {String} email
   * @return {Boolean}
   */
  requestOtpLoginLink(email) {
    const url = this.api.buildRequestURL("/:serviceKey/api/user/requestOTPLoginLink", {
      ":serviceKey": API_SERVICE_KEY_CORE_AUTH,
    });
    const body = {
      email,
    };
    return this.api.request("POST", url, body).then((result) => result.done);
  }
}
