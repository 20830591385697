import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { PageContext } from '../Context/PageProvider'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Select, MenuItem, FormControl, InputLabel, List, ListItem, IconButton, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { REPLACEMENT_TYPE, REPLACEMENT_DATE_TYPE } from '../../../m2m-cloud-api/Api/AppMesageService/Models/Replacement'
import ReplacementChecklistItemDialog from './ReplacementChecklistItemDialog'
import { mapErrorMessage } from '../../../Libs/Utilities/ApiHelper'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { v4 as uuidv4 } from 'uuid'


class ReplacementDialog extends React.Component {

    constructor(props) {
        super(props)

        this.prepareDesc = (type) => {
            let desc = { optional: false }
            switch (type) {
                case REPLACEMENT_TYPE.CHECKLIST:
                    desc.values = []
                    break;
                case REPLACEMENT_TYPE.ENUM:
                    desc.values = ['']
                    break;
                case REPLACEMENT_TYPE.INTEGER:
                    desc.min = 0
                    desc.max = 99
                    desc.allowDecimals = false
                    break;
                case REPLACEMENT_TYPE.DATE:
                    desc.type = REPLACEMENT_DATE_TYPE.DATE
                default:
                    break;
            }
            return desc
        }

        const type = props.replacement && props.replacement.getType() || REPLACEMENT_TYPE.TEXT || REPLACEMENT_TYPE.NOTE

        this.state = {
            id: props.replacement && props.replacement.getId() || null,
            name: props.replacement && props.replacement.getName() || '',
            type: type,
            desc: props.replacement && props.replacement.getDesc() || this.prepareDesc(type),
            loading: false,
            errorMessage: null,
            selectedCheckListItemIndex: null
        }
    }


    isEditMode() {
        const { replacement } = this.props
        return replacement ? true : false
    }

    handleSubmit() {
        const { replacement, orgId } = this.props
        const { name, type, desc } = this.state

        this.setState({ loading: true, errorMessage: null })

        const upsertReplacement = (replacementId) => {

            if (desc.min) {
                desc.min = Number(desc.min)
            }
            if (desc.max) {
                desc.max = Number(desc.max)
            }

            this.context.api.appMessageService.upsertTextReplacement(replacementId, orgId, name, type, desc)
                .then(replacement => {
                    this.props.onSuccess(replacement)
                })
                .catch(error => {
                    this.setState({
                        errorMessage: mapErrorMessage(error)
                    })
                })
                .finally(() => this.setState({ loading: false }))
        }

        if (this.isEditMode()) {
            upsertReplacement(replacement.getId())
        } else {
            const replacementId = uuidv4()
            upsertReplacement(replacementId)
        }
    }

    render() {
        const { type, name, desc, loading, errorMessage } = this.state
        const { t, classes } = this.props
        const invisibleTypes = [REPLACEMENT_TYPE.CHECKLIST, REPLACEMENT_TYPE.REPORT, REPLACEMENT_TYPE.NOTE]

        return (
            <div>
                <Dialog open={this.props.open} onClose={this.props.onCancel} fullWidth maxWidth={'xs'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.isEditMode() ? t('edit_replacement') : t('add_replacement')}</DialogTitle>
                    <Divider />
                    <DialogContent className={classes.dialogContent}>
                        <ValidatorForm
                            ref="form"
                            onSubmit={this.handleSubmit.bind(this)}
                            onError={errors => console.log("form error:", errors)}
                        >

                            <TextValidator
                                className={classes.field}
                                value={name}
                                onChange={(event) => this.setState({ name: event.target.value })}
                                margin="dense"
                                multiline={false}
                                label={t('name')}
                                validators={['required', 'trim']}
                                errorMessages={[t('this_field_is_required'), t('this_field_is_required')]}
                                fullWidth
                            />

                            {this.renderTypeField()}
                            {this.renderTypeFields()}

                            {invisibleTypes.indexOf(type) === -1 && <div className={classes.checkboxContainer}>
                                <FormControlLabel
                                    control={
                                        <Checkbox className={classes.checkbox} disabled={desc.serviceProvider} checked={desc.optional} onChange={(event) => {
                                            let newDesc = desc || {}
                                            newDesc.optional = event.target.checked
                                            this.setState({ desc: newDesc })
                                        }} name="optional"
                                        />
                                    }
                                    label={t('this_field_is_optional')}
                                />
                                {[REPLACEMENT_TYPE.BAR_CODE, REPLACEMENT_TYPE.QR_CODE].indexOf(type) === -1 && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox className={classes.checkbox} checked={desc.serviceProvider} onChange={(event) => {
                                                let newDesc = desc || {}
                                                newDesc.serviceProvider = event.target.checked
                                                if (newDesc.serviceProvider) {
                                                    newDesc.optional = true
                                                    newDesc.editable = false
                                                }
                                                this.setState({ desc: newDesc })
                                            }} name="only_for_service_provider"
                                            />
                                        }
                                        label={t('only_for_service_provider')}
                                    />
                                )}
                                <FormControlLabel
                                    control={
                                        <Checkbox className={classes.checkbox} checked={desc.key} onChange={(event) => {
                                            let newDesc = desc || {}
                                            newDesc.key = event.target.checked
                                            this.setState({ desc: newDesc })
                                        }} name="key"
                                        />
                                    }
                                    label={t('key')} />
                            </div>}

                            {errorMessage && <DialogContentText className={classes.errorText}>
                                {errorMessage}
                            </DialogContentText>}
                        </ValidatorForm>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button disabled={loading} onClick={this.props.onCancel}>
                            {t('cancel')}
                        </Button>
                        <Button disabled={loading} onClick={() => this.refs.form.submit()} color="primary">
                            {this.isEditMode() ? t('edit_replacement') : t('add_replacement')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    renderTypeField() {
        const { type } = this.state
        const { t, classes, replacement } = this.props

        const allowDeprecatedReportType = replacement?.getType() === REPLACEMENT_TYPE.REPORT

        return (
            <div className={classes.fieldContainer}>
                <FormControl className={classes.formControl} fullWidth margin="dense">
                    <InputLabel id="replacement-type">{t('replacement_type')}</InputLabel>
                    <Select
                        labelId="replacement-type"
                        value={type}
                        onChange={(event) => {
                            const type = event.target.value
                            const desc = this.prepareDesc(type)
                            this.setState({ type, desc })
                        }}>
                        <MenuItem value={REPLACEMENT_TYPE.TEXT}>{t('text_value')}</MenuItem>
                        <MenuItem value={REPLACEMENT_TYPE.INTEGER}>{t('integer_value')}</MenuItem>
                        <MenuItem value={REPLACEMENT_TYPE.ENUM}>{t('list_of_values')}</MenuItem>
                        <MenuItem value={REPLACEMENT_TYPE.DATE}>{t('date')}</MenuItem>
                        <MenuItem value={REPLACEMENT_TYPE.QR_CODE}>{t('qrcode_value')}</MenuItem>
                        <MenuItem value={REPLACEMENT_TYPE.BAR_CODE}>{t('barcode_value')}</MenuItem>
                        <MenuItem value={REPLACEMENT_TYPE.CHECKLIST}>{t('checklist')}</MenuItem>
                        {allowDeprecatedReportType && <MenuItem value={REPLACEMENT_TYPE.REPORT}>{t('report')}</MenuItem>}
                        <MenuItem value={REPLACEMENT_TYPE.IMAGE}>{t('image')}</MenuItem>
                        <MenuItem value={REPLACEMENT_TYPE.NOTE}>{t('note')}</MenuItem>
                    </Select>
                </FormControl>
            </div>
        )
    }

    renderTypeFields() {
        const { type } = this.state
        return (
            <Fragment>
                {type === REPLACEMENT_TYPE.INTEGER && this.renderIntegerFields()}
                {type === REPLACEMENT_TYPE.TEXT && this.renderMaxCharacterFields()}
                {type === REPLACEMENT_TYPE.ENUM && this.renderEnumFields()}
                {type === REPLACEMENT_TYPE.DATE && this.renderDateFields()}
                {type === REPLACEMENT_TYPE.CHECKLIST && this.renderChecklistFields()}
            </Fragment>
        )
    }

    renderMaxCharacterFields() {
        const { desc } = this.state
        const { t, classes } = this.props

        const minCharacterLength = 1
        const maxCharacterLength = 200

        return (
            <div className={[classes.fieldContainer].join(' ')}>
                <TextValidator
                    fullWidth
                    className={classes.maxCharField}
                    value={desc.max}
                    onChange={(event) => {
                        let newDesc = desc || {}
                        newDesc.max = event.target.value
                        this.setState({ desc: newDesc })
                    }}
                    margin="dense"
                    multiline={false}
                    label={t('max_character_length')}
                    validators={['required', 'isNumber', `minNumber:${minCharacterLength}`, `maxNumber:${maxCharacterLength}`]}
                    errorMessages={[t('this_field_is_required'), t('invalid_number'), t('allowed_minimum_value', { min: minCharacterLength }), t('allowed_maximum_value', { max: maxCharacterLength })]}
                />
            </div>
        )
    }

    /**
     * integer
     */
    renderIntegerFields() {
        const { desc } = this.state
        console.log('desc:', desc)
        const { t, classes } = this.props

        const minValue = 0

        return (
            <Fragment>
                <div className={[classes.fieldContainer, classes.minMaxFieldContainer].join(' ')}>
                    <TextValidator
                        className={classes.minField}
                        value={desc.min}
                        onChange={(event) => {
                            let newDesc = desc || {}
                            newDesc.min = event.target.value
                            this.setState({ desc: newDesc })
                        }}
                        margin="dense"
                        multiline={false}
                        label={t('min_value')}
                        validators={['required', 'isNumber', `minNumber:${minValue}`]}
                        errorMessages={[t('this_field_is_required'), t('invalid_number'), t('allowed_minimum_value', { min: minValue })]}
                    />
                    <TextValidator
                        className={classes.maxField}
                        value={desc.max}
                        onChange={(event) => {
                            let newDesc = desc || {}
                            newDesc.max = event.target.value
                            this.setState({ desc: newDesc })
                        }}
                        margin="dense"
                        multiline={false}
                        label={t('max_value')}
                        validators={['required', 'isNumber', `minNumber:${minValue}`]}
                        errorMessages={[t('this_field_is_required'), t('invalid_number'), t('allowed_minimum_value', { min: minValue })]}
                    />
                </div>
                <div className={classes.fieldContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checkbox}
                                id={'allow-decimals'}
                                checked={desc.allowDecimals}
                                onChange={(event) => {
                                    let newDesc = desc || {}
                                    newDesc.allowDecimals = event.target.checked
                                    this.setState({ desc: newDesc })
                                }}
                                label={t('allow_decimals')}
                                margin="dense"
                                color='secondary'
                            />
                        }
                        label={t('allow_decimals')}
                    />
                </div>
                <div className={classes.fieldContainer} style={{ marginTop: 0 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className={classes.checkbox}
                                id={'editable'}
                                disabled={desc.serviceProvider}
                                checked={desc.editable}
                                onChange={(event) => {
                                    let newDesc = desc || {}
                                    newDesc.editable = event.target.checked
                                    this.setState({ desc: newDesc })
                                }}
                                label={t('editable')}
                                margin="dense"
                                color='secondary'
                            />
                        }
                        label={t('editable')}
                    />
                </div>
            </Fragment>
        )
    }

    /**
     * enum
     */
    renderEnumFields() {
        const { desc } = this.state
        const { t, classes } = this.props
        return (
            <div className={[classes.fieldContainer, classes.listFieldContainer].join(' ')}>
                <InputLabel id="enum-label" className={classes.listValuesTitle}>{t('available_values')}</InputLabel>
                <Divider />
                <List component="nav" className={classes.list} aria-label="enum">
                    {desc.values.map((value, index) => (
                        <ListItem key={'enum' + index} className={classes.listItem} disableGutters>
                            <TextValidator
                                fullWidth
                                className={[classes.textField, classes.textFieldValue].join(' ')}
                                placeholder={t('enter_value')}
                                value={value}
                                onChange={(event) => this.handleEnumChange(index, event.target.value)}
                                validators={['required']}
                                errorMessages={[t('this_field_is_required')]}
                                InputLabelProps={{ shrink: true }} />
                            <IconButton
                                className={classes.deleteIcon}
                                size="small"
                                onClick={() => this.deleteEnumRow(index)}
                                edge="end">
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                    <Button
                        color="primary"
                        className={classes.addButton}
                        onClick={() => this.addEnumRow()}
                        startIcon={<AddIcon />}>
                        {t('add')}
                    </Button>
                </List>
            </div>
        )
    }

    addEnumRow() {
        const desc = this.state.desc
        desc.values.push('')
        this.setState({
            desc
        })
    }

    handleEnumChange(itemIndex, value) {
        const desc = this.state.desc
        desc.values[itemIndex] = value
        this.setState({
            desc
        })
    }

    deleteEnumRow(index) {
        const values = this.state.desc.values.filter((val, i) => i !== index)
        const desc = this.state.desc
        desc.values = values
        this.setState({
            desc
        })
    }

    /**
     * checklist
     */
    renderChecklistFields() {
        const { selectedCheckListItemIndex, desc } = this.state
        const { t, classes } = this.props
        return (
            <div className={[classes.fieldContainer, classes.listFieldContainer].join(' ')}>
                <InputLabel id="checklist-label" className={classes.listValuesTitle}>{t('available_values')}</InputLabel>
                <Divider />
                <List component="nav" className={classes.list} aria-label="checklist">
                    {desc.values.map((value, index) => (
                        <ListItem key={'checklist' + index} className={classes.listItem} disableGutters>
                            <Typography style={{ flex: 1 }}>
                                {value.title}
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => this.setState({ selectedCheckListItemIndex: index })}>
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                size="small"
                                onClick={() => this.deleteChecklistRow(index)}
                                edge="end">
                                <DeleteIcon />
                            </IconButton>
                        </ListItem>
                    ))}
                    <Button
                        color="primary"
                        className={classes.addButton}
                        onClick={() => this.setState({ selectedCheckListItemIndex: -1 })}
                        startIcon={<AddIcon />}>
                        {t('add')}
                    </Button>
                </List>
                <ReplacementChecklistItemDialog
                    open={selectedCheckListItemIndex !== null}
                    item={selectedCheckListItemIndex === null ? null : this.state.desc.values[selectedCheckListItemIndex]}
                    onCancel={() => this.setState({ selectedCheckListItemIndex: null })}
                    onSuccess={(title, content) => {
                        const desc = this.state.desc
                        if (selectedCheckListItemIndex === -1) {
                            desc.values.push({ title, content })
                        } else if (selectedCheckListItemIndex >= 0) {
                            desc.values[selectedCheckListItemIndex] = { title, content }
                        }
                        this.setState({
                            desc,
                            selectedCheckListItemIndex: null
                        })
                    }} />
            </div>
        )
    }

    deleteChecklistRow(index) {
        const values = this.state.desc.values.filter((val, i) => i !== index)
        const desc = this.state.desc
        desc.values = values
        this.setState({
            desc
        })
    }

    /**
     * date
     */
    renderDateFields() {
        const { t, classes } = this.props
        return (
            <FormControl className={classes.formControl} fullWidth margin="dense">
                <InputLabel id="replacement-type">{t('date_input_type')}</InputLabel>
                <Select
                    labelId="replacement-type"
                    value={this.state.desc.type}
                    onChange={(event) => {
                        const desc = this.state.desc
                        desc.type = event.target.value
                        this.setState({
                            desc
                        })
                    }}>
                    <MenuItem value={REPLACEMENT_DATE_TYPE.DATE}>{t('date')}</MenuItem>
                    <MenuItem value={REPLACEMENT_DATE_TYPE.TIME}>{t('time')}</MenuItem>
                    <MenuItem value={REPLACEMENT_DATE_TYPE.DATETIME}>{t('datetime')}</MenuItem>
                </Select>
            </FormControl>
        )
    }

}

ReplacementDialog.contextType = PageContext

ReplacementDialog.propTypes = {
    open: PropTypes.bool,
    orgId: PropTypes.any,
    replacement: PropTypes.any,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

const styles = theme => ({
    dialogContent: {
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(4)
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(3)
    },
    fieldContainer: {
        marginTop: theme.spacing(2)
    },
    minMaxFieldContainer: {
        display: 'flex',
    },
    minField: {
        marginRight: theme.spacing(2),
        flex: 1
    },
    maxField: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    listValuesTitle: {
        marginBottom: theme.spacing(1),
    },
    listFieldContainer: {
        marginTop: theme.spacing(3)
    },
    checkboxContainer: {
        '& label': {
            display: 'flex'
        }
    },
    checkbox: {
        padding: '4px 9px'
    }
})


export default withTranslation()(withStyles(styles)(ReplacementDialog))