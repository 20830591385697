import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Api, UserService, OrgService, AuthzService, DeviceService, AppMessageService, ERROR, ORG_TAG as ORG_TAGS } from '../index';
import { PARAM_PRIORITY, buildRootOrgNicknameParam, PARAM_APP_MSG_ITEM_SORT, PARAM_THEME } from '../Api/OrgService/Models/Org';
import {
    APP_MODE as _APP_MODE,
    ORG_PARAM_KEY_ACCESSIBILITY,
    ORG_PARAM_KEY_ACCESSIBILITY_ADMINS_EXCLUDED,
    ORG_PARAM_KEY_ROOT_ORG_SUPPLIER_NAME,
    ORG_PARAM_KEY_ROOT_ORG_RECIPIENT_NAME,
    USER_PARAM_KEY_RECIPIENT,
    USER_PARAM_KEY_SUPPLIER,
    SETTING_SELECTED_SUPPLIER_ORG_ID,
    SETTING_USER_FONT_SIZE,
} from './Contants';
import { SETTINGS_CODE_TOKEN } from '../Api/Drivers/CodeDriver';
import { prepareTemplateContent } from '../Api/Helper';
import UserParams from './UserParams';
import { REPLACEMENT_TYPE } from '../Api/AppMesageService/Models/Replacement';
import { PARAM_ON_HOLD } from '../Api/AppMesageService/Models/Message';
import { ALL_GROUP_ORG_TAGS } from '../Api/OrgService/OrgService';

const moment = require('moment');

export const ApiContext = React.createContext();

const SYNC_TIME_INTERVAL = 10000;

export const APP_MODE = _APP_MODE;
export const ORG_TAG = ORG_TAGS;

const getSelectedSupplierOrgIdKey = parentOrgId => {
    return `${SETTING_SELECTED_SUPPLIER_ORG_ID}-${parentOrgId}`;
};

const mergeReplacements = (replacements, newReplacements) => {
    const _replacements = replacements ? [...replacements] : [];
    if (newReplacements && newReplacements.length > 0) {
        newReplacements.map(replacement => {
            const existingReplacementIndex = _replacements.findIndex(_rep => _rep.getId() === replacement.getId());
            if (existingReplacementIndex >= 0) {
                _replacements[existingReplacementIndex] = replacement;
            } else {
                _replacements.push(replacement);
            }
        });
    }
    return _replacements;
};

/**
 * sort orgs given ids
 * @param {String} itemIds
 * @param {Array} items
 * @returns {Array}
 */
export const sortItems = (itemIds, items) => {
    if (!itemIds || itemIds.length <= 0) {
        return items.sort((a, b) => {
            return a.getName ? a.getName().localeCompare(b.getName()) : a.title.localeCompare(b.title);
        });
    } else {
        const arrayIds = itemIds.split(',');
        items.sort((a, b) => {
            let indexA = arrayIds.findIndex(id => id === (a.getId ? a.getId() : a.id));
            if (indexA === -1) {
                indexA = items.length;
            }
            let indexB = arrayIds.findIndex(id => id === (b.getId ? b.getId() : b.id));
            if (indexB === -1) {
                indexB = items.length;
            }
            return indexA - indexB;
        });
        return items;
    }
};

export const findRootOrg = (orgs, org) => {
    let parentOrg = null;
    let rootOrg = null;
    if (org && orgs && orgs.length > 0 && org) {
        parentOrg = orgs.find(_org => _org.getId() === org.getParentId());
        const check = () => {
            if (parentOrg && parentOrg.hasTags([ORG_TAG.ROOT])) {
                rootOrg = parentOrg;
            }
        };
        check();
        do {
            parentOrg = orgs.find(_org => parentOrg && _org.getId() === parentOrg.getParentId());
            check();
        } while (parentOrg && !rootOrg);
    }
    return rootOrg;
};

class ApiProvider extends Component {
    constructor(props) {
        super(props);

        // sync timer
        this.syncTimer = null;

        // pending invitations timer
        this.pendingInvitationsTimer = null;

        this.loadPendingInvitations = async () => {
            try {
                const pendingInvitations = await this.authzService.listPendingInvitations();
                //console.log('pendingInvitations', pendingInvitations)
                this.setState({ pendingInvitations });
            } catch (error) {
                console.warn('ApiContext, loadPendingInvitations error: ', error);
            }
        };

        this.onInvitationAccepted = () => {
            this.loadPendingInvitations();
            //this.loadAllOrgs()
        };

        this.setAsyncState = async state => {
            return new Promise(resolve => {
                this.setState(state, resolve);
            });
        };

        //init api
        this.initApi(props.domain);

        this.state = {
            ready: false, // ready to sync
            userFontSize: null, // custom user font size
            lastError: null, // last fetched error
            syncing: false, // syncing data
            allOrgs: null,
            allAccessibleOrgs: null,
            appMode: null, // app mode, see APP_MODE
            deviceAssignedOrgs: null, // device assigned orgs (recipients), displayed in supplier list as recipient
            completedMessageOrgs: null, // completed message orgs
            replacements: null, // replacements from config org

            publicUsers: null, // all public users
            supplierMessages: null, // supplier messages ( state: open, processing )
            rotationMode: false, // supplier rotation mode
            recipientMessages: null, // recipient messages ( state: open, processing )
            recipientArticles: null, // recipient articles to order,
            pendingInvitations: null,
            devices: [], // all cached devices
            definitionTemplates: {}, // all cached definition templates

            changedParams: {},
        };

        this.cacheDevices = async deviceIds => {
            const deviceIdsToLoad = [];
            deviceIds.map(deviceId => {
                if (!this.state.devices.find(device => device.getId() === deviceId)) {
                    deviceIdsToLoad.push(deviceId);
                }
            });

            if (deviceIdsToLoad.length === 0) return Promise.resolve();

            const devices = await this.deviceService.getDeviceMultiple(deviceIdsToLoad);

            // load definitions with device id's
            const definitions = await this.appMessageService.getDefinitionsByMultipleDeviceId(deviceIdsToLoad);

            // fetch all template id's
            const templateIds = [];
            const definitionTemplateIds = {};
            definitions.map(definition => {
                if (templateIds.indexOf(definition.getTemplateId()) === -1) {
                    templateIds.push(definition.getTemplateId());
                    definitionTemplateIds[definition.getId()] = definition.getTemplateId();
                }
            });

            // load templates with template id's
            const templates = await this.appMessageService.getMultipleTemplates(templateIds);
            const definitionTemplates = {};
            Object.keys(definitionTemplateIds).map(definitionId => {
                definitionTemplates[definitionId] = templates.find(template => template.getId() === definitionTemplateIds[definitionId]);
            });

            return new Promise(async (resolve, reject) => {
                this.setState(
                    {
                        devices: [...this.state.devices, ...devices],
                        definitionTemplates: { ...this.state.definitionTemplates, ...definitionTemplates },
                    },
                    () => resolve(),
                );
            });
        };

        this.sortMessageReplacements = messages => {
            for (let index = 0; index < messages.length; index++) {
                const message = messages[index];
                const params = message.getParams(true);
                if (params && Object.keys(params).length > 0) {
                    const definitionId = message.getDefinitionId();
                    const template = definitionId && this.state.definitionTemplates[definitionId];
                    if (template && template.getReplacementIds()) {
                        const sortedParamIds = Object.keys(params).sort((replacementIdA, replacementIdB) => {
                            const a = template.getReplacementIds().indexOf(replacementIdA);
                            const b = template.getReplacementIds().indexOf(replacementIdB);
                            return a - b;
                        });
                        const sortedParams = {};
                        for (let ii = 0; ii < sortedParamIds.length; ii++) {
                            const sortedParamId = sortedParamIds[ii];
                            sortedParams[sortedParamId] = params[sortedParamId];
                        }
                        messages[index].params = sortedParams;
                    }
                }
            }
            return messages;
        };

        // init user session
        this.userService
            .initSession()
            .then(() => this.onSessionReady())
            .catch(error => {
                console.log('initSession- error', error);
                this.setState({ ready: true }, () => {
                    const errorType = error && error.getErrorType && error.getErrorType();
                    if (errorType === ERROR.FORBIDDEN || errorType === ERROR.UNAUTHORIZED || errorType === ERROR.USER_NOT_FOUND || errorType === ERROR.NOT_FOUND) {
                        this.props.debugEnabled && console.log('session expired, logout user...');
                        this.userService.logoutUser();
                    } else {
                        console.log('error', error);
                    }
                });
            });
    }

    /**
     * init api
     * @param {String} domain
     */
    initApi(domain) {
        this.cleanState(true);
        this.api = new Api(domain, this.props.storage, this.props.debugEnabled);
        this.userService = new UserService(
            this.api,
            () => {
                // onLogin
                this.onSessionReady().then(() => {
                    if (this.props.onLogin) {
                        this.props.onLogin();
                    }
                });
            },
            () => {
                // onLogout
                this.stopSync();
                this.cleanState(true);
                if (this.props.onLogout) {
                    this.props.onLogout();
                }
                if (this.pendingInvitationsTimer) {
                    clearInterval(this.pendingInvitationsTimer);
                    this.pendingInvitationsTimer = null;
                }
            },
            user => {
                // onBeforeLogout
                if (this.props.onBeforeLogout) {
                    this.props.onBeforeLogout(user);
                }
            },
        );

        this.orgService = new OrgService(this.api);
        this.authzService = new AuthzService(this.api);
        this.deviceService = new DeviceService(this.api);
        this.appMessageService = new AppMessageService(this.api);

        this.userParams = new UserParams(this.userService);
    }

    /**
     * set domain
     * @param {String} domain
     */
    setDomain(domain) {
        this.api.setDomain(domain);
    }

    /**
     * on session ready handler ( valid token exists and user object is loaded )
     * @returns {undefined}
     */
    onSessionReady() {
        return new Promise(async (resolve, reject) => {
            const user = this.userService.getActiveUser();
            const userFontSize = await this.api.storage.get(SETTING_USER_FONT_SIZE);
            if (userFontSize) {
                this.state.userFontSize = Number(userFontSize);
            }
            await this.loadAllOrgs();
            await this.setAsyncState({ ready: true });
            await this.startSync();
            if (this.props.onReady) {
                this.props.onReady(user);
            }
            if (this.pendingInvitationsTimer) clearInterval(this.pendingInvitationsTimer);
            this.pendingInvitationsTimer = setInterval(() => {
                this.loadPendingInvitations();
            }, 60 * 1000);
            this.loadPendingInvitations();

            if (this.state.appMode === APP_MODE.RECIPIENT) {
                try {
                    await this.loadArticles();
                } catch (error) {
                    console.warn('loadArticles, error', error);
                }
                resolve();
            } else {
                resolve();
            }
        });
    }

    /**
     * set user custom font size
     * @param {*} value
     */
    setUserFontSize(value) {
        this.setState({ userFontSize: value });
        this.api.storage.set(SETTING_USER_FONT_SIZE, value);
    }

    /**
     * filter accessible orgs
     * @param {Array<Org>} orgs
     * @returns {Array<Org>}
     */
    async filterAccessibleOrgs(orgs) {
        const user = this.userService.getActiveUser();

        const rootOrgIdsToCheck = [];
        const rootOrgs = {};
        let writeableRootOrgIds = [];
        for (let index = 0; index < orgs.length; index++) {
            const org = orgs[index];
            if (org && org.hasTags([ORG_TAG.GROUP, ORG_TAG.SUPPLIER_TARGET, ORG_TAG.RECIPIENT_LOCATION])) {
                const rootOrg = findRootOrg(orgs, org);
                rootOrgs[org.getId()] = rootOrg ? rootOrg.getId() : null;
                if (rootOrg && rootOrg.getId() && rootOrgIdsToCheck.indexOf(rootOrg.getId()) === -1) {
                    rootOrgIdsToCheck.push(rootOrg.getId());
                }
            }
        }

        if (rootOrgIdsToCheck.length > 0) {
            try {
                writeableRootOrgIds = await this.orgService.checkAccessMultiple('w', rootOrgIdsToCheck);
            } catch (error) {
                console.warn('filterAccessibleOrgs, checkAccessMultiple error: ', error);
            }
        }

        const hasAccess = org => {
            let _hasAccess = true;

            const access = org.getParam(ORG_PARAM_KEY_ACCESSIBILITY);
            if (access !== null && access !== undefined && access.trim() !== '*') {
                const userIds = access.split(',');
                _hasAccess = user && userIds.indexOf(user.getUserId()) >= 0;
            }
            if (!_hasAccess) {
                const rootOrgId = rootOrgs[org.getId()];
                _hasAccess = rootOrgId && writeableRootOrgIds.indexOf(rootOrgId) >= 0;
            }
            if (_hasAccess) {
                const accessExcluded = org.getParam(ORG_PARAM_KEY_ACCESSIBILITY_ADMINS_EXCLUDED);
                if (accessExcluded !== null && accessExcluded !== undefined) {
                    const excludedUserIds = accessExcluded.split(',');
                    if (user && excludedUserIds.indexOf(user.getUserId()) >= 0) {
                        _hasAccess = false;
                    }
                }
            }
            return _hasAccess;
        };

        return orgs.filter(org => hasAccess(org));
    }

    /**
     * search accessible orgs
     * @param {Object} params
     * @returns {Array<Org>}
     */
    searchAccessibleOrgs(params) {
        return this.orgService.searchOrg(params).then(async orgs => this.filterAccessibleOrgs(orgs));
    }

    /**
     * clean context state
     * @param {Boolean} cleanOrgs
     *
     * @returns {undefined}
     */
    async cleanState(cleanOrgs) {
        let newState = {
            lastError: null,
            publicUsers: null,
            recipientArticles: null,
            recipientMessages: null,
            supplierMessages: null,
        };
        if (cleanOrgs) {
            newState['deviceAssignedOrgs'] = null;
            newState['allOrgs'] = null;
            newState['allAccessibleOrgs'] = null;
        }
        await this.setAsyncState(newState);
    }

    /**
     * set recipient selected orgs
     * @param {Array<String>} orgIds
     * @param {Boolean} setAppModeToRecipient
     * @returns {Undefined}
     */
    async setRecipientOrgIds(orgIds, setAppModeToRecipient = false) {
        console.warn('setRecipientOrgIds', orgIds);
        await this.cleanState(false);
        if (orgIds.length > 0) {
            this.userParams.setRecipientOrgIds(orgIds);
            if (setAppModeToRecipient) {
                await this.setAppMode(APP_MODE.RECIPIENT);
            }
            await this.loadArticles();
        } else {
            console.warn('Invalid recipient orgs...', orgIds);
        }
        console.warn('setRecipientOrgIds end', orgIds);
    }

    /**
     * get selected recipient object org
     * @returns {Org | null}
     */
    getSelectedRecipientObjectOrg() {
        const recipientSelectedOrgs = this.userParams.getRecipientOrgIds();
        if (recipientSelectedOrgs && recipientSelectedOrgs.length > 0) {
            const recipientObjectOrgId = recipientSelectedOrgs[recipientSelectedOrgs.length - 1];
            return this.fetchKnownOrg(recipientObjectOrgId);
        }
        return null;
    }

    /**
     * set supplier selected org ids
     * @param {Array<String>} orgIds
     * @param {Boolean} setAppModeToSupplier
     * @returns {Undefined}
     */
    async setSupplierParentOrgIds(orgIds, setAppModeToSupplier = false) {
        if (orgIds.length > 0) {
            await this.userParams.setSupplierParentOrgIds(orgIds);
            if (setAppModeToSupplier) {
                await this.setAppMode(APP_MODE.SUPPLIER);
            }
        } else {
            console.warn('Invalid supplier orgs...', orgIds);
        }
    }

    /**
     * get last selected supplier org
     * @returns {Org | null}
     */
    async getLastSelectedSupplierOrg() {
        const { allAccessibleOrgs } = this.state;
        const supplierSelectedOrgs = this.userParams.getSupplierParentOrgIds();
        const supplierParentOrgId = supplierSelectedOrgs && supplierSelectedOrgs.length > 0 ? supplierSelectedOrgs[supplierSelectedOrgs.length - 1] : null;
        if (supplierParentOrgId) {
            let orgId = await this.api.storage.get(getSelectedSupplierOrgIdKey(supplierParentOrgId));
            if (orgId) {
                return allAccessibleOrgs.find(org => org.getId() === orgId);
            }
            const supplierOrgs = this.getSupplierOrgs();
            return supplierOrgs.length > 0 ? supplierOrgs[0] : null;
        }
        return null;
    }

    /**
     * set last selected supplier org id
     * @param {Org} supplierOrg
     */
    async setLastSelectedSupplierOrg(supplierOrg) {
        const supplierSelectedOrgs = this.userParams.getSupplierParentOrgIds();
        const supplierParentOrgId = supplierSelectedOrgs && supplierSelectedOrgs.length > 0 ? supplierSelectedOrgs[supplierSelectedOrgs.length - 1] : null;
        await this.api.storage.set(getSelectedSupplierOrgIdKey(supplierParentOrgId), supplierOrg.getId());
    }

    /**
     * get all supplier orgs from selected supplier org
     * @returns
     */
    getSupplierOrgs() {
        const { allAccessibleOrgs, allOrgs } = this.state;
        const supplierSelectedOrgs = this.userParams.getSupplierParentOrgIds();
        const supplierParentOrgId = supplierSelectedOrgs && supplierSelectedOrgs.length > 0 ? supplierSelectedOrgs[supplierSelectedOrgs.length - 1] : null;
        if (supplierParentOrgId) {
            const supplierParentOrg = allOrgs.find(org => org.getId() === supplierParentOrgId);
            const itemIds = supplierParentOrg ? supplierParentOrg.getParam(`${PARAM_APP_MSG_ITEM_SORT}-${USER_PARAM_KEY_SUPPLIER}`) : null;
            const supplierOrgs = supplierParentOrg ? allAccessibleOrgs.filter(org => org.getParentId() === supplierParentOrg.getId() && org.getTags().indexOf(ORG_TAG.SUPPLIER_TARGET) !== -1) : [];
            return sortItems(itemIds, supplierOrgs);
        }
        return null;
    }

    /**
     * toggle rotation mode
     * @returns {undefined}
     */
    toggleRotationMode() {
        this.setState({ rotationMode: !this.state.rotationMode });
    }

    /**
     * set app mode
     * @param {APP_MODE} appMode
     * @param {func | null} callback
     * @returns {undefined}
     */
    async setAppMode(appMode, callback) {
        await this.cleanState(false);
        await this.setAsyncState({ appMode });
        await this.userParams.setAppMode(appMode);
        if (appMode !== APP_MODE.SELECT_SUPPLIER_OR_RECIPIENT) {
            this.restartSync();
        }
        if (callback) callback();
    }

    /**
     * Start syncing
     * @param {Boolean} needsRefreshOrgs
     * @param {Func} callback
     * @returns {undefined}
     */
    async startSync(needsRefreshOrgs = false, callback = null) {
        const _startSync = () => {
            if (this.syncTimer === null) {
                this.syncTimer = setInterval(() => {
                    this.sync();
                }, SYNC_TIME_INTERVAL);
                this.sync();
            } else {
                this.props.debugEnabled && console.log('currently syncing!');
            }
            if (callback) callback();
        };

        if (this.userService.getActiveUser() && needsRefreshOrgs) {
            await this.loadAllOrgs();
        }
        _startSync();
    }

    /**
     * Stop syncing
     * @returns {undefined}
     */
    stopSync() {
        if (this.syncTimer) {
            clearInterval(this.syncTimer);
            this.syncTimer = null;
        }
    }

    /**
     * Restart syncing
     * @returns {undefined}
     */
    restartSync() {
        this.stopSync();
        this.startSync();
    }

    /**
     * Sync data from API
     */
    sync() {
        if (!this.userService.getActiveUser()) {
            this.props.debugEnabled && console.log('has no active user, stop syncing...');
            this.stopSync();
            return;
        }
        if (!this.state.syncing) {
            switch (this.state.appMode) {
                case APP_MODE.SUPPLIER:
                    this.loadSupplierData();
                    break;
                case APP_MODE.RECIPIENT:
                    this.loadRecipientData();
                    break;
                default:
                    break;
            }
        }
    }

    /**
     * load all orgs from server
     * @returns {undefined}
     */
    async loadAllOrgs() {
        try {
            const orgs = await this.orgService.searchOrg({ tags: Object.values(ORG_TAG) });
            await this.setAsyncState({ allAccessibleOrgs: await this.filterAccessibleOrgs(orgs), allOrgs: orgs });
        } catch (error) {
            console.warn("can't load orgs from server, error: ", error);
        }
    }

    /**
     * load and cache last completed message orgs
     * @param {Array<Uid>} orgIds
     * @returns {Array<Org>}
     */
    async loadAndCacheCompletedMessageOrgs(orgIds) {
        const orgs = await this.orgService.readMultiple(orgIds);
        this.setState({ completedMessageOrgs: orgs });
        return orgs;
    }

    /**
     * Get known Org
     * @param {Uid} orgId
     * @returns {Org}
     */
    fetchKnownOrg(orgId) {
        const knownOrgs = [...this.state.allOrgs, ...(this.state.completedMessageOrgs ? this.state.completedMessageOrgs : [])];
        const org = knownOrgs.find(org => org.getId() === orgId);
        return org ? org : null;
    }

    /**
     * fetch known parent org
     * @param {Org} org
     * @param {ORG_TAG} orgTag
     */
    fetchKnownParentGroupOrgs(org) {
        const orgs = [];
        let _org = org;
        do {
            let parentOrg = null;
            if (_org && _org.getParentId()) {
                const _parentOrg = this.fetchKnownOrg(_org.getParentId());
                if (_parentOrg && _parentOrg.hasTags(ALL_GROUP_ORG_TAGS)) {
                    parentOrg = _parentOrg;
                }
            }
            if (parentOrg) {
                orgs.push(parentOrg);
            }
            _org = parentOrg;
        } while (_org);
        return orgs.reverse();
    }

    /**
     * Get known Device
     * @param {Uid} deviceId
     * @returns {Device}
     */
    fetchKnownDevice(deviceId) {
        return this.state.devices.find(device => device.getId() === deviceId);
    }

    /**
     * Load and cache replacements
     */
    async loadAndCacheReplacementsByIds(ids) {
        const replacementDefs = await this.appMessageService.readMultipleReplacementDefs(ids);
        this.setState({
            replacements: mergeReplacements(this.state.replacements, replacementDefs),
        });
        return replacementDefs;
    }

    /**
     * Load articles from API
     * @returns {undefined}
     */
    async loadArticles() {
        const recipientLocationOrg = this.getSelectedRecipientObjectOrg();
        if (!recipientLocationOrg) {
            console.log("[loadArticles] can't find selected recipient object org: ", this.userParams.getRecipientOrgIds(), 'recipientSelectedOrgs', this.userParams.getRecipientOrgIds());
            return;
        }

        try {
            const devices = await this.deviceService.searchDevice(recipientLocationOrg.getId());

            // fetch all device id's to get definitions
            const deviceIds = [];
            devices.map(device => {
                if (device.getDriver() === 'code' && deviceIds.indexOf(device.getId()) === -1) {
                    deviceIds.push(device.getId());
                }
            });

            // load definitions with device id's
            const definitions = await this.appMessageService.getDefinitionsByMultipleDeviceId(deviceIds);

            // fetch all template id's
            const templateIds = [];
            definitions.map(definition => {
                if (templateIds.indexOf(definition.getTemplateId()) === -1) {
                    templateIds.push(definition.getTemplateId());
                }
            });

            // load templates with template id's
            const templates = await this.appMessageService.getMultipleTemplates(templateIds);

            // fetch all replacement id's
            const replacementIds = [];
            templates.map(template => {
                const templateReplacements = template && template.getReplacementIds();
                templateReplacements &&
                    templateReplacements.map(templateReplacement => {
                        if (replacementIds.indexOf(templateReplacement) === -1 && templateReplacement.indexOf(PARAM_ON_HOLD) !== 0) {
                            replacementIds.push(templateReplacement);
                        }
                    });
            });
            const replacementDefs = await this.appMessageService.readMultipleReplacementDefs(replacementIds);
            const recipientArticles = [];
            definitions.map(definition => {
                const device = devices.find(device => device.getId() === definition.getDeviceId());
                const template = templates.find(template => template.getId() === definition.getTemplateId());
                if (template && definition.events && definition.events.length > 0) {
                    const templateReplacements = template.getReplacementIds();
                    const replacements = [];
                    templateReplacements.map(replacementId => {
                        const foundReplacement = replacementDefs && replacementDefs.find(replacement => replacement.getId() === replacementId);
                        if (foundReplacement) replacements.push(foundReplacement);
                    });
                    const preparedTemplate = prepareTemplateContent(template, definition, device);
                    const physicalId = device.getPhysicalId();
                    recipientArticles.push({
                        id: definition.getId(),
                        definition: definition,
                        device: device,
                        definitionId: definition.getId(), //
                        definitionOrgId: definition.getOrgId(), //
                        namespace: physicalId.substring(0, physicalId.indexOf(':')), //
                        code: physicalId.substring(physicalId.indexOf(':') + 1), //
                        token: device.getSetting(SETTINGS_CODE_TOKEN), //
                        eventName: definition.events[0], //
                        eventValue: '1', //
                        title: preparedTemplate.header,
                        replacementIds: replacements,
                    });
                } else {
                    console.warn('invalid definition or template, definition: ', definition, ' template: ', template);
                }
            });
            const itemIds = recipientLocationOrg.getParam(PARAM_APP_MSG_ITEM_SORT);
            const _recipientArticles = sortItems(itemIds, recipientArticles);
            this.setState({ recipientArticles: _recipientArticles, replacements: mergeReplacements(this.state.replacements, replacementDefs) });
        } catch (error) {
            console.warn("can't load articles, error: ", error);
        }
    }

    /**
     * build article object for given definition
     * @param {Definition} definition
     * @returns {Object}
     */
    async buildArticleForDefinition(definition) {
        const device = await this.deviceService.getDevice(definition.getDeviceId());
        const template = await this.appMessageService.getTemplate(definition.getTemplateId());
        const preparedTemplate = prepareTemplateContent(template, definition, device);
        const physicalId = device.getPhysicalId();

        if (template && definition.events && definition.events.length > 0) {
            const templateReplacements = template.getReplacementIds();
            const replacements = await this.appMessageService.readMultipleReplacementDefs(templateReplacements.filter(id => id.indexOf(PARAM_ON_HOLD) !== 0));

            const article = {
                definition: definition,
                device: device,
                definitionId: definition.getId(), //
                definitionOrgId: definition.getOrgId(), //
                namespace: physicalId.substring(0, physicalId.indexOf(':')), //
                code: physicalId.substring(physicalId.indexOf(':') + 1), //
                token: device.getSetting(SETTINGS_CODE_TOKEN), //
                eventName: definition.events[0], //
                eventValue: '1', //
                title: preparedTemplate.header,
                replacementIds: replacements,
            };
            return article;
        }
    }

    /**
     * get triggerable recipient articles
     * @returns {Array<Message>}
     */
    getTriggerableRecipientArticles() {
        const { recipientArticles } = this.state;
        return recipientArticles && recipientArticles.filter(article => this.canTriggerArticle(article));
    }

    /**
     * can trigger article
     * @param {Message} article
     * @returns {Boolean}
     */
    canTriggerArticle(article) {
        const { recipientMessages } = this.state;
        if (!recipientMessages) return false;
        const foundMessage = recipientMessages.find(message => {
            if (message.getDefinitionId() === article.definition.getId()) {
                const replacementIds = article.replacementIds && article.replacementIds.filter(replacementId => replacementId.getType() !== REPLACEMENT_TYPE.REPORT); // ignore reports
                return !(replacementIds && replacementIds.length > 0);
            }
            return false;
        });
        return foundMessage ? false : true;
    }

    /**
     * Load recipient data from API
     * @returns {undefined}
     */
    loadRecipientData() {
        let publicUserIds = [];

        const recipientLocationOrg = this.getSelectedRecipientObjectOrg();
        if (!recipientLocationOrg) {
            console.warn("[loadRecipientData] can't find selected recipient object org: ", this.userParams.getRecipientOrgIds(), 'recipientSelectedOrgs', this.userParams.getRecipientOrgIds());
            return;
        }

        this.props.debugEnabled && console.log('[loadRecipientData] recipient org: ', recipientLocationOrg.getId());

        const start = moment().subtract(1, 'year').startOf('day');
        const end = moment().endOf('day');

        return this.appMessageService
            .readMultipleMessagesByDeviceOrg([recipientLocationOrg.getId()], 0, 1000, false, start.toDate(), end.toDate())
            .then(async _recipientMessages => {
                const deviceIds = [];
                _recipientMessages.map(message => {
                    if (deviceIds.indexOf(message.getTriggeringDeviceId()) === -1) {
                        deviceIds.push(message.getTriggeringDeviceId());
                    }

                    const orgPriority = recipientLocationOrg.getParam(PARAM_PRIORITY);
                    if (orgPriority) {
                        message.setPriority(Number(orgPriority));
                    }

                    const acknowledgedUserId = message.getAcknowledgedUserId();
                    const processingUserId = message.getProcessingUserId();
                    const triggeringUserId = message.getTriggeringUserId();
                    if (acknowledgedUserId && publicUserIds.indexOf(acknowledgedUserId) === -1) {
                        publicUserIds.push(acknowledgedUserId);
                    }
                    if (processingUserId && publicUserIds.indexOf(processingUserId) === -1) {
                        publicUserIds.push(processingUserId);
                    }
                    if (triggeringUserId && publicUserIds.indexOf(triggeringUserId) === -1) {
                        publicUserIds.push(triggeringUserId);
                    }
                });

                const recipientMessages = _recipientMessages.sort((a, b) => {
                    if (a.getPriority() === b.getPriority()) {
                        return a.isProcessing() === b.isProcessing() ? a.getHeader().localeCompare(b.getHeader()) : a.isProcessing() ? -1 : 1;
                    } else {
                        return b.getPriority() - a.getPriority();
                    }
                });

                try {
                    await this.cacheDevices(deviceIds);
                } catch (error) {
                    console.log('cacheDevices error', error);
                }
                return this.loadPublicUsers(publicUserIds).then(publicUsers => {
                    const newState = {
                        publicUsers,
                        recipientMessages,
                        syncing: false,
                    };
                    this.setState(newState);
                    return newState;
                });
            })
            .catch(error => {
                console.log('readMultipleMessagesByDeviceOrg -  error', error);
            });
    }

    /**
     * Sort given message items
     * @param {Array} messages 
     * @param {Boolean} isProcessingMessages
     * @returns {Array<messages>}
     */
    sortMessages(messages, isProcessingMessages = false) {
        if (!messages) return []
        const filteredOnHoldMessages = messages.filter(message => message.getParam(PARAM_ON_HOLD) === 'true')
        let filteredNoHoldMessages = messages.filter(message => !message.getParam(PARAM_ON_HOLD) || message.getParam(PARAM_ON_HOLD) === 'false')

        filteredNoHoldMessages = filteredNoHoldMessages.sort((a, b) => {
            if (a.getPriority() === b.getPriority()) {
                return isProcessingMessages ? a.getProcessingDate() - b.getProcessingDate() : a.ts - b.ts;
            } else {
                return b.getPriority() - a.getPriority();
            }
        })
        return [...filteredNoHoldMessages, ...filteredOnHoldMessages]
    }

    /**
     * Load supplier data from API
     * @returns {undefined}
     */
    loadSupplierData() {
        const { allOrgs } = this.state;

        const supplierOrgs = this.getSupplierOrgs();
        if (!supplierOrgs) {
            console.warn('[loadSupplierData] no orgs available!');
            return;
        }

        this.setState({ lastError: null, syncing: true }, async () => {
            try {
                let supplierMessages = {};
                let publicUserIds = [];
                let replacementIds = [];

                let deviceAssignedOrgs = this.state.deviceAssignedOrgs ? this.state.deviceAssignedOrgs : [];
                if (allOrgs) {
                    allOrgs.map(org => {
                        if (!deviceAssignedOrgs.find(currentOrg => currentOrg.getId() === org.getId())) {
                            deviceAssignedOrgs.push(org);
                        }
                    });
                }
                const deviceAssignedOrgIdsToLoad = [];

                for (let i = 0; i < supplierOrgs.length; i++) {
                    const org = supplierOrgs[i];
                    if (org) {
                        supplierMessages[org.getId()] = [];
                    }
                }

                const start = moment().subtract(1, 'year').startOf('day');
                const end = moment().endOf('day');

                const orgsMessages = await this.appMessageService.readMultipleMessagesByDefinitionOrg(Object.keys(supplierMessages), 0, 1000, false, start.toDate(), end.toDate());
                const triggeringDeviceIds = [];
                orgsMessages.map(message => {
                    if (!supplierMessages[message.getDefinitionOrgId()]) supplierMessages[message.getDefinitionOrgId()] = [];
                    supplierMessages[message.getDefinitionOrgId()].push(message);

                    if (triggeringDeviceIds.indexOf(message.getTriggeringDeviceId()) === -1) {
                        triggeringDeviceIds.push(message.getTriggeringDeviceId());
                    }

                    const deviceAssignedOrgId = message.getDeviceAssignedOrgId();
                    if (deviceAssignedOrgId) {
                        const alreadyLoadedAssignedOrg = deviceAssignedOrgs.find(org => org.getId() === deviceAssignedOrgId);
                        if (!alreadyLoadedAssignedOrg && deviceAssignedOrgIdsToLoad.indexOf(deviceAssignedOrgId) === -1) {
                            deviceAssignedOrgIdsToLoad.push(deviceAssignedOrgId);
                        }
                    }

                    const messageReplacementIds = message.getParams() && Object.keys(message.getParams());
                    messageReplacementIds &&
                        messageReplacementIds.map(id => {
                            const existingReplacement = this.state.replacements && this.state.replacements.find(_rep => _rep.getId() === id);
                            if (!existingReplacement && replacementIds.indexOf(id) === -1 && id.indexOf(PARAM_ON_HOLD) !== 0) replacementIds.push(id); // ignore custom onhold params
                        });

                    const acknowledgedUserId = message.getAcknowledgedUserId();
                    const processingUserId = message.getProcessingUserId();
                    const triggeringUserId = message.getTriggeringUserId();
                    if (acknowledgedUserId && publicUserIds.indexOf(acknowledgedUserId) === -1) {
                        publicUserIds.push(acknowledgedUserId);
                    }
                    if (processingUserId && publicUserIds.indexOf(processingUserId) === -1) {
                        publicUserIds.push(processingUserId);
                    }
                    if (triggeringUserId && publicUserIds.indexOf(triggeringUserId) === -1) {
                        publicUserIds.push(triggeringUserId);
                    }
                });

                const publicUsers = await this.loadPublicUsers(publicUserIds);
                await this.cacheDevices(triggeringDeviceIds);

                if (deviceAssignedOrgIdsToLoad.length > 0) {
                    const _orgs = await this.orgService.readMultiple(deviceAssignedOrgIdsToLoad);
                    _orgs.map(org => {
                        if (!deviceAssignedOrgs.find(currentOrg => currentOrg.getId() === org.getId())) {
                            deviceAssignedOrgs.push(org);
                        }
                    });
                }

                // sort messages
                Object.keys(supplierMessages).map(orgId => {
                    const messages = supplierMessages[orgId];
                    if (messages && messages.length) {
                        for (let index = 0; index < messages.length; index++) {
                            const message = messages[index];
                            const deviceAssignedOrg = deviceAssignedOrgs.find(currentOrg => currentOrg.getId() === message.getDeviceAssignedOrgId());
                            const orgPriority = deviceAssignedOrg && deviceAssignedOrg.getParam(PARAM_PRIORITY);

                            if (orgPriority) {
                                message.setPriority(Number(orgPriority));
                            }
                        }

                        supplierMessages[orgId] = this.sortMessages(messages)
                    }
                });

                let newReplacements = this.state.replacements;
                if (replacementIds.length > 0) {
                    const replacementDefs = await this.appMessageService.readMultipleReplacementDefs(replacementIds);
                    newReplacements = mergeReplacements(newReplacements, replacementDefs);
                }

                this.setState({
                    deviceAssignedOrgs,
                    replacements: newReplacements,
                    publicUsers,
                    supplierMessages,
                    syncing: false,
                });
            } catch (error) {
                console.log('loadSupplierData - error', error);
                this.setState({ lastError: error, syncing: false });
            }
        });
    }

    /**
     * trigger article event
     * @param {MessageDefinition} definition
     * @param {Device} device
     * @param {Object<replacementId,value>} replacementIdValues
     *
     * @returns {Promise}
     */
    triggerArticleEvent(definition, device, replacementIdValues) {
        const deviceAssignedOrgId = device.getAssignedOrg();

        /*if (this.state.recipientLocationOrg && deviceAssignedOrgId !== this.state.recipientLocationOrg.getId()) {
                const deviceAssignedOrg = this.state.recipientOrgs.find(org => org.getId() === deviceAssignedOrgId)
                if (!deviceAssignedOrg) {
                    throw (new Error('error_foreign_code'))
                }
                this.setRecipientLocation(deviceAssignedOrg)
            }*/

        const physicalId = device.getPhysicalId();
        const namespace = physicalId.substring(0, physicalId.indexOf(':'));
        const code = physicalId.substring(physicalId.indexOf(':') + 1);
        const token = device.getSetting(SETTINGS_CODE_TOKEN);
        const eventName = definition.events[0];
        const eventValue = '1';

        const emitEvent = () =>
            new Promise((resolve, reject) => {
                this.deviceService.codeDriver.emitEvent(namespace, code, eventName, token, eventValue, null, replacementIdValues).then(result => {
                    setTimeout(() => {
                        return this.loadRecipientData()
                            .then(({ recipientMessages }) => {
                                resolve(result);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }, 300);
                });
            });

        const isSameValue = (a, b) => a && b && a.trim() === b.trim();

        return this.loadRecipientData().then(({ recipientMessages }) => {
            const openedOrProcessingDefinition =
                recipientMessages &&
                recipientMessages.find(message => {
                    if (message.getDefinitionId() === definition.getId()) {
                        if (replacementIdValues && Object.keys(replacementIdValues).length > 0) {
                            const keyReplacements = (this.state.replacements && this.state.replacements.filter(replacement => (replacement.desc && replacement.desc.key ? replacement.id : null))) || [];
                            const hasSameReplacementValues = keyReplacements.find(replacement => isSameValue(replacementIdValues[replacement.id], message.params[replacement.id])) ? true : false;
                            return hasSameReplacementValues;
                        }
                        return true;
                    }
                    return false;
                });
            if (openedOrProcessingDefinition) {
                throw new Error('open_or_processing_order_already_exists');
            }
            return emitEvent();
        });
    }

    /**
     * Load public users from API (cached)
     * @param {Array<Uid>} userIds
     * @returns {Array<Promise>}
     */
    loadPublicUsers(userIds) {
        let promisses = [];
        userIds.map(userId => {
            promisses.push(this.userService.getPublicUser(userId));
        });
        return Promise.all(promisses).then(publicUsers => {
            if (publicUsers && publicUsers.length > 0) {
                publicUsers.map(publicUser => {
                    const rootOrgNickname = this.state.rootOrg && this.state.rootOrg.getParam(buildRootOrgNicknameParam(publicUser.getId()));
                    if (rootOrgNickname) {
                        publicUser.setNickname(rootOrgNickname);
                    }
                });
                return publicUsers;
            }
        });
    }

    /**
     * Set message processing
     * @param {Uid} messageId
     * @returns {undefined}
     */
    setMessageProcessing(messageId) {
        return this.appMessageService.setMessageProcessing(messageId).then(message => {
            this.updateMessage(message);
            return message;
        });
    }

    /**
     * Set message from processing to open
     * @param {Uid} messageId
     * @returns {undefined}
     */
    unsetMessageProcessing(messageId) {
        return this.appMessageService.unsetMessageProcessing(messageId).then(message => {
            this.updateMessage(message);
            return message;
        });
    }

    /**
     * Set message acknowledged
     * @param {Uid} messageId
     * @returns {undefined}
     */
    setMessageAcknowledged(messageId) {
        return this.appMessageService.setMessageAck(messageId, this.getChangedParamValues(messageId)).then(message => {
            this.updateMessage(message);
            const changedParams = this.state.changedParams;
            delete changedParams[messageId];
            this.setState({ changedParams });
            return message;
        });
    }

    /**
     * Set changed message replacement value
     * @param {uuid} messageId
     * @param {uuid} replacementId
     * @param {Any | null} newValue
     * @return {undefined}
     */
    setChangedParamValue(messageId, replacementId, newValue) {
        const changedParams = this.state.changedParams;
        if (!changedParams[messageId]) {
            changedParams[messageId] = {};
        }
        if (newValue === null) {
            delete changedParams[messageId][replacementId];
        } else {
            changedParams[messageId][replacementId] = newValue;
        }
        this.setState({ changedParams });
    }

    /**
     * Get changed message replacement values
     * @param {uuid} messageId
     * @returns {Object | null}
     */
    getChangedParamValues(messageId) {
        const changedParams = this.state.changedParams[messageId];
        if (changedParams && Object.keys(changedParams).length > 0) {
            return changedParams;
        }
        return null;
    }

    /**
     * Set message acknowledged report
     * @param {Uid} messageId
     * @returns {undefined}
     */
    setMessageAcknowledgedReport(messageId, message) {
        return this.appMessageService.setMessageAckReport(messageId, message).then(message => {
            this.updateMessage(message);
            return message;
        });
    }

    /**
     * Delete message
     * @param {Uid} messageId
     * @returns {Boolean}
     */
    deleteMessage(messageId) {
        let { supplierMessages, recipientMessages } = this.state;

        const supplierMessagesBeforeDelete = Object.assign({}, supplierMessages);
        const recipientMessagesBeforeDelete = recipientMessages ? [...recipientMessages] : null;

        // remove message from context state
        if (supplierMessages && messageId && Object.keys(supplierMessages).length > 0) {
            Object.keys(supplierMessages).map(orgId => {
                const orgMessages = supplierMessages[orgId];
                if (orgMessages && orgMessages.length > 0) {
                    orgMessages.map((message, index) => {
                        if (message.getId() === messageId) {
                            delete supplierMessages[orgId][index];
                        }
                    });
                }
            });
        }

        recipientMessages = recipientMessages ? recipientMessages.filter(message => message.getId() !== messageId) : null;
        this.setState({
            recipientMessages,
            supplierMessages,
        });

        return new Promise((resolve, reject) => {
            this.appMessageService
                .deleteMessage(messageId)
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    // set state before delete message
                    this.setState(
                        {
                            recipientMessages: recipientMessagesBeforeDelete,
                            supplierMessages: supplierMessagesBeforeDelete,
                        },
                        () => reject(error),
                    );
                });
        });
    }

    /**
     * Update local message (state)
     * @param {Message} newMessage
     * @returns {undefined}
     */
    updateMessage(newMessage) {
        const { supplierMessages } = this.state;
        let updated = false;
        if (supplierMessages && newMessage && Object.keys(supplierMessages).length > 0) {
            Object.keys(supplierMessages).map(orgId => {
                const orgMessages = supplierMessages[orgId];
                if (orgMessages && orgMessages.length > 0) {
                    orgMessages.map((message, index) => {
                        if (message.getId() === newMessage.getId()) {
                            updated = true;
                            supplierMessages[orgId][index] = newMessage;
                        }
                    });
                }
            });
        }
        if (updated) {
            this.setState({
                supplierMessages,
            });
        }
    }

    /**
     * get custom supplier name
     * @returns {String}
     */
    /*getCustomSupplierName() {
          const { rootOrg } = this.state
          let name = null
          if (rootOrg) {
              const rootOrgName = rootOrg.getParam(ORG_PARAM_KEY_ROOT_ORG_SUPPLIER_NAME)
              if (rootOrgName && rootOrgName.trim() !== "") {
                  name = rootOrgName.trim()
              }
          }
          if (this.state.groupConfigOrg) {
              const configOrgName = this.userParams.getSupplierNameFromUser(this.state.groupConfigOrg.getId())
              if (configOrgName && configOrgName.trim() !== "") {
                  name = configOrgName.trim()
              }
          }
          return name
      }*/

    /**
     * get custom recipient name
     * @returns {String}
     */
    /*getCustomRecipientName() {
          const { rootOrg } = this.state
          let name = null
          if (rootOrg) {
              const rootOrgName = rootOrg.getParam(ORG_PARAM_KEY_ROOT_ORG_RECIPIENT_NAME)
              if (rootOrgName && rootOrgName.trim() !== "") {
                  name = rootOrgName.trim()
              }
          }
          if (this.state.groupConfigOrg) {
              const configOrgName = this.userParams.getRecipientNameFromUser(this.state.groupConfigOrg.getId())
              if (configOrgName && configOrgName.trim() !== "") {
                  name = configOrgName.trim()
              }
          }
          return name
      }*/

    /**
     * get get root org themes
     * @returns {Array<Object>}
     */
    getRootOrgThemes() {
        const user = this.userService.getActiveUser();
        if (user) {
            const supplierParentOrgIds = this.userParams.getSupplierParentOrgIds();
            if (supplierParentOrgIds?.length > 0) {
                const rootOrg = this.fetchKnownOrg(supplierParentOrgIds[0]);
                const themesString = rootOrg?.getParam(PARAM_THEME);
                try {
                    const themes = (themesString && JSON.parse(themesString)) || [];
                    return themes;
                } catch (error) {
                    console.log('theme parse error', error);
                }
            }
        }
        return null;
    }

    /**
     * get selected root org
     * @returns Object
     */
    getRootOrgTheme() {
        const user = this.userService.getActiveUser();
        if (user) {
            const supplierParentOrgIds = this.userParams.getSupplierParentOrgIds();
            if (supplierParentOrgIds?.length > 0) {
                const rootOrg = this.fetchKnownOrg(supplierParentOrgIds[0]);
                const themesString = rootOrg?.getParam(PARAM_THEME);
                try {
                    const themes = (themesString && JSON.parse(themesString)) || [];
                    const selectedTheme = this.userParams.getSelectedTheme();
                    let theme = null;
                    if (selectedTheme) {
                        theme = themes.find(_theme => _theme.id === selectedTheme);
                    }
                    if (!theme) {
                        theme = themes.find(_theme => _theme.default);
                    }
                    return theme;
                } catch (error) {
                    console.log('theme parse error', error);
                }
            }
        }
        return null;
    }

    /**
     * set selected root org theme
     * @param {String} themeId
     */
    setRootOrgTheme(themeId) {
        this.userParams.setSelectedTheme(themeId);
        this.forceUpdate();
    }

    render() {
        return (
            <ApiContext.Provider
                value={{
                    //init api
                    initApi: this.initApi.bind(this),

                    // api domain
                    setDomain: this.setDomain.bind(this),

                    // all accessible orgs
                    allAccessibleOrgs: this.state.allAccessibleOrgs,

                    // fetch org from cache
                    fetchKnownOrg: this.fetchKnownOrg.bind(this),

                    // load and cache completed message orgs
                    loadAndCacheCompletedMessageOrgs: this.loadAndCacheCompletedMessageOrgs.bind(this),

                    // fetch parent group orgs from cache
                    fetchKnownParentGroupOrgs: this.fetchKnownParentGroupOrgs.bind(this),

                    // fetch device from cache
                    fetchKnownDevice: this.fetchKnownDevice.bind(this),

                    // get public user from given user Id
                    getPublicUser: userId => (this.state.publicUsers ? this.state.publicUsers.find(publicUser => publicUser.getId() === userId) : null),
                    // load public users from API
                    loadPublicUsers: this.loadPublicUsers.bind(this),

                    // get pending invitations from current user
                    pendingInvitations: this.state.pendingInvitations,

                    // reload pending invitations
                    loadPendingInvitations: this.loadPendingInvitations.bind(this),

                    // on invitation accepted handler
                    onInvitationAccepted: this.onInvitationAccepted.bind(this),

                    // set app mode recipient / supplier
                    setAppMode: this.setAppMode.bind(this),
                    // get app mode
                    appMode: this.state.appMode,

                    // start sync messages / articles
                    startSync: this.startSync.bind(this),
                    // stop sync
                    stopSync: this.stopSync.bind(this),
                    // restart sync messages / articles
                    restartSync: this.restartSync.bind(this),

                    // search accessible orgs
                    searchAccessibleOrgs: this.searchAccessibleOrgs.bind(this),

                    // get root org theme
                    getRootOrgTheme: this.getRootOrgTheme.bind(this),
                    // set root org theme
                    setRootOrgTheme: this.setRootOrgTheme.bind(this),
                    // get root org themes
                    getRootOrgThemes: this.getRootOrgThemes.bind(this),

                    // get custom supplier name
                    // getCustomSupplierName: this.getCustomSupplierName.bind(this),
                    // set custom supplier name
                    // setCustomSupplierName: (supplierName) => this.state.groupConfigOrg ? this.userParams.setSupplierNameFromUser(this.state.groupConfigOrg.getId(), supplierName) : null,
                    // get supplier notification enabled
                    getSupplierNotificationEnabled: orgId => this.userParams.getSupplierNotificationEnabled(orgId),
                    // set supplier notification enabled
                    setSupplierNotificationEnabled: (orgId, enabled) => this.userParams.setSupplierNotificationEnabled(orgId, enabled),
                    // get supplier alert enabled
                    getSupplierAlertEnabled: orgId => this.userParams.getSupplierAlertEnabled(orgId),
                    // set supplier alert enabled
                    setSupplierAlertEnabled: (orgId, enabled) => this.userParams.setSupplierAlertEnabled(orgId, enabled),
                    // get supplier orgs
                    getSupplierOrgs: this.getSupplierOrgs.bind(this),
                    // get messages from given supplier org Id
                    getSupplierMessages: orgId => (this.state.supplierMessages ? this.state.supplierMessages[orgId] : null),
                    // get supplier rotation mode
                    rotationMode: this.state.rotationMode,
                    // toggle supplier rotation mode
                    toggleRotationMode: this.toggleRotationMode.bind(this),

                    // get custom recipient name
                    // getCustomRecipientName: this.getCustomRecipientName.bind(this),
                    // set custom recipient name
                    // setCustomRecipientName: (recipientName) => this.state.groupConfigOrg ? this.userParams.setRecipientNameFromUser(this.state.groupConfigOrg.getId(), recipientName) : null,
                    // get orgs from selected recipient
                    recipientOrgs: this.state.recipientOrgs,
                    // get messages from selected recipient
                    recipientMessages: this.state.recipientMessages ? this.state.recipientMessages : null,
                    // get articles from selected recipient ( definitions )
                    recipientArticles: this.state.recipientArticles ? this.state.recipientArticles : null,
                    // build article object for given definition
                    buildArticleForDefinition: this.buildArticleForDefinition.bind(this),
                    // get triggerable articles from selected recipient ( definitions )
                    getTriggerableRecipientArticles: this.getTriggerableRecipientArticles.bind(this),
                    // trigger article event
                    triggerArticleEvent: this.triggerArticleEvent.bind(this),
                    // load and cache replacements by id
                    loadAndCacheReplacementsByIds: this.loadAndCacheReplacementsByIds.bind(this),
                    // get repolacements from selected config org
                    replacements: this.state.replacements,
                    // sort given messages
                    sortMessages: this.sortMessages.bind(this),

                    // set message is processing
                    setMessageProcessing: this.setMessageProcessing.bind(this),
                    // unset message is processing
                    unsetMessageProcessing: this.unsetMessageProcessing.bind(this),
                    // set message is acknowledged
                    setMessageAcknowledged: this.setMessageAcknowledged.bind(this),
                    // set message is acknowledged report
                    setMessageAcknowledgedReport: this.setMessageAcknowledgedReport.bind(this),
                    // delete message
                    deleteMessage: this.deleteMessage.bind(this),

                    // get API user service
                    userService: this.userService,
                    // get API org service
                    orgService: this.orgService,
                    // get API authz service
                    authzService: this.authzService,
                    // get API app message service
                    appMessageService: this.appMessageService,
                    // get API device service
                    deviceService: this.deviceService,

                    // sort replacement from given messages
                    sortMessageReplacements: messages => this.sortMessageReplacements(messages),

                    // load and cache devices
                    loadAndCacheDevices: deviceIds => this.cacheDevices(deviceIds),

                    // set changed message replacement value
                    setChangedParamValue: this.setChangedParamValue.bind(this),
                    // get changed message replacement value
                    getChangedParamValues: this.getChangedParamValues.bind(this),

                    // set user font size
                    userFontSize: this.state.userFontSize,
                    // set user font size
                    setUserFontSize: this.setUserFontSize.bind(this),

                    // get selected recipient org ids
                    getRecipientOrgIds: () => this.userParams.getRecipientOrgIds(),
                    // set selected recipient org ids
                    setRecipientOrgIds: this.setRecipientOrgIds.bind(this),
                    // get selected recipient object org
                    getSelectedRecipientObjectOrg: this.getSelectedRecipientObjectOrg.bind(this),

                    // get selected supplier org ids
                    getSupplierParentOrgIds: () => this.userParams.getSupplierParentOrgIds(),
                    // set selected supplier org ids
                    setSupplierParentOrgIds: this.setSupplierParentOrgIds.bind(this),
                    // get supplier orgs
                    getSupplierOrgs: this.getSupplierOrgs.bind(this),
                    // get last selected supplier org id from local storage
                    getLastSelectedSupplierOrg: this.getLastSelectedSupplierOrg.bind(this),
                    // set last selected supplier org id from local storage
                    setLastSelectedSupplierOrg: this.setLastSelectedSupplierOrg.bind(this),
                }}>
                {this.state.ready && <Fragment>{this.props.children}</Fragment>}
            </ApiContext.Provider>
        );
    }
}

const PageConsumer = ApiContext.Consumer;

ApiProvider.propTypes = {
    domain: PropTypes.string.isRequired,
    storage: PropTypes.any.isRequired,
    debugEnabled: PropTypes.bool,
    onReady: PropTypes.func,
    onLogin: PropTypes.func,
    onLogout: PropTypes.func,
    onBeforeLogout: PropTypes.func,
};

export default ApiProvider;
export { PageConsumer };
